/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState } from 'react';
import { DataContext } from '../../providers/dataProvider';
import { fetchAPI } from '../../util/data/fetchAPI.util';
import { BookmarkType } from '../../types/BookmarkType';
import BookmarkMover from '../BookmarkMover/BookmarkMover.component';
import Modal from '../Modal/Modal.component';
import { Wrapper } from './multiSelect.style';
import { Button } from '../../styles/button.style';
import { useMediaQuery } from 'react-responsive';
import { TbArrowMoveUpFilled, TbHandMove, TbSelectAll } from 'react-icons/tb';
import { FaRegCopy } from 'react-icons/fa';
import { IoTrashOutline } from 'react-icons/io5';

interface MutliSelectProps {
  selectedBookmarks: string[];
  setSelectedBookmarks: (selectedBookmarkIds: string[]) => void;
  isEditable: number;
  activeCollection: any;
}

export const MultiSelect = (props: MutliSelectProps): JSX.Element => {
  const { selectedBookmarks, setSelectedBookmarks, isEditable, activeCollection } = props;
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [isCopy, setIsCopy] = useState(false);

  const isMobile = useMediaQuery({
    query: '(max-width: 800px)',
  });

  const { userCollections, collectionBookmarks, setCollectionBookmarks } = useContext(DataContext);

  const selectAllBookmarks = (): void => {
    if (selectedBookmarks?.length === collectionBookmarks.length) {
      setSelectedBookmarks([]);
      return;
    }

    setSelectedBookmarks(collectionBookmarks.map((bookmark: BookmarkType) => bookmark?.Id));
  };

  const moveMultipleToTrash = (): void => {
    fetchAPI
      .post('/apiv2/removeMultipleBookmarks', {
        headers: {
          id: selectedBookmarks,
        },
      })
      .then(() => {
        setCollectionBookmarks((prevState: any[]) =>
          prevState.filter((bookmark: { Id: string }) => !(selectedBookmarks || []).includes(bookmark.Id)),
        );
        setSelectedBookmarks([]);
      })
      .catch(console.error);
  };

  return (
    <>
      <Wrapper>
        <Button size='lg' backgroundColor='#3243c0' onClick={() => selectAllBookmarks()}>
          {isMobile ? <TbSelectAll /> : 'Select All'}
        </Button>
        <Button
          size='lg'
          backgroundColor='white'
          style={{ color: 'black' }}
          onClick={() => {
            setShowMoveModal(true);
            setIsCopy(true);
          }}
        >
          {isMobile ? <FaRegCopy /> : 'Copy Selected'}
        </Button>
        {isEditable === 1 && (
          <>
            <Button
              size='lg'
              backgroundColor='white'
              style={{ color: 'black' }}
              onClick={() => {
                setShowMoveModal(true);
                setIsCopy(false);
              }}
            >
              {isMobile ? <TbHandMove style={{ filter: 'brightness(0)' }} /> : 'Move Selected'}
            </Button>
            <Button size='lg' backgroundColor='#851b1c' onClick={() => moveMultipleToTrash()}>
              {isMobile ? <IoTrashOutline /> : 'Delete Selected'}
            </Button>
          </>
        )}
      </Wrapper>

      <Modal
        show={showMoveModal}
        onClose={() => {
          setShowMoveModal(false);
        }}
        maxWidth={570}
      >
        <BookmarkMover
          id={selectedBookmarks}
          isCopy={isCopy}
          userCollections={userCollections}
          activeCollection={activeCollection}
          onClose={() => {
            setShowMoveModal(false);
            setSelectedBookmarks([]);
          }}
        />
      </Modal>
    </>
  );
};
