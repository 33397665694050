import React, { useState, useEffect, useContext } from 'react';
import { getToken } from '../../util/auth/firebase';
import { DataContext } from '../../providers/dataProvider';
import { Form, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { FaEyeSlash } from 'react-icons/fa';
import { Box, HStack, Input, Stack, Text, VStack } from '@chakra-ui/react';
import { Button } from '../../styles/button.style';
import { ErrorText } from './collectionPin.style';

export default function CollectionPin(props: any) {
  const [hasPinSet, setHasPinSet] = useState(false);
  const [pinAccepted, setPinAccepted] = useState(false);
  const [collectionPin, setCollectionPin] = useState('');
  const [pinMessage, setPinMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { setUserCollections } = useContext(DataContext);

  useEffect(() => {
    if (localStorage.getItem('user')) {
      setHasPinSet(true);
    }
  }, []);

  const getAllCollections = async (ev: { preventDefault: () => void } | undefined) => {
    ev?.preventDefault();
    setCollectionPin('');
    setIsLoading(true);

    const accessToken = await getToken();

    //TODO: create a fetchAPI helper
    fetch('/apiv2/getCollectionsInclude', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        collectionpin: collectionPin,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('Not 2xx response', { cause: response });
        }
        return response.json();
      })
      .then((data) => {
        const collectionSort = data.sort(function (
          a: { DateShared: any; DateCreated: any },
          b: { DateShared: any; DateCreated: any },
        ) {
          return new Date(b.DateShared || b.DateCreated).getTime() - new Date(a.DateShared || a.DateCreated).getTime();
        });
        setUserCollections(collectionSort);
        setIsLoading(false);
        setPinAccepted(true);
      })
      .catch((err) => {
        console.error(err);
        setPinMessage('Pin is incorrect');
      })
      .finally(() => setIsLoading(false));
  };

  if (pinAccepted) {
    return (
      <Stack justifyContent='center' alignItems='center' textAlign='center'>
        <Text fontSize='22px' margin='0'>
          Pin Accepted
        </Text>
        <Text fontSize='18px' margin='0'>
          Your hidden collections are now visible and will be displayed in the collections list with the following icon.
        </Text>
        <Box marginTop='20px'></Box>
        <FaEyeSlash size='100px' />

        <Text fontSize='18px' margin='0'>
          You can now close this dialog.
        </Text>
        <Button background='#2c77e7' color='white' onClick={() => props.close()}>
          Close
        </Button>
      </Stack>
    );
  }

  return (
    <div id='collection-editor' className='pb-0'>
      <VStack justifyContent='flex-start' alignItems='flex-start'>
        <Text fontSize='22px' margin='0'>
          Collections Pin
        </Text>
        <Text>Enter your pin and hit "Enter" to see any hidden collections you may have.</Text>
        <Form onSubmit={getAllCollections} style={{ width: '100%' }}>
          {hasPinSet ? (
            <HStack position='relative'>
              <Input
                type='password'
                placeholder='Pin...'
                value={collectionPin}
                onChange={(e) => setCollectionPin(e.target.value)}
                autoFocus
                spellCheck={false}
                autoComplete='off'
              />
              <Box position='absolute' right='10px'>
                {isLoading && <Spinner animation='border' variant='primary' size='sm' />}
              </Box>
            </HStack>
          ) : (
            <Text>No pin found!</Text>
          )}
        </Form>
        {pinMessage && <ErrorText>{pinMessage}</ErrorText>}

        <Text>
          Haven&apos;t got a pin? set one up{' '}
          <Link to='#' onClick={() => props.openSettings()}>
            here
          </Link>
        </Text>
      </VStack>
    </div>
  );
}
