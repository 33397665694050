import { useEffect, useState } from 'react';
import { fetchAPI } from '../../util/data/fetchAPI.util';
import { getCollectionFromURL } from '../../util/navigation/navigation.util';

export const useGetCollectionSharedUsers = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const activeCollectionId = getCollectionFromURL() as string;

  const getUsersInCollection = async () => {
    fetchAPI
      .get('/apiv2/getUsersInCollection', {
        headers: {
          CollectionId: activeCollectionId,
        },
      })
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        setError(err);
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getUsersInCollection();
  }, []);

  return {
    loading,
    data,
    error,
  };
};
