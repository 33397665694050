import { HStack, VStack, Text } from '@chakra-ui/react';
import { Button } from './appButton.style';

interface AppButtonProps {
  src: string;
  header: string;
  label: string;
  link: string;
}

export const AppButton = (props: AppButtonProps) => {
  const { src, header, label, link } = props;

  return (
    <Button onClick={() => window.open(link)} variant='plain' className='app-store-btn'>
      <HStack>
        <img src={src} width={label === 'Google Play' ? '40px' : '30px'} height='30px' alt='download the app' />
        <VStack gap='0px' alignItems='flex-start' marginTop='-8px'>
          <Text fontSize={12}>{header}</Text>
          <Text as='h5' fontSize={12}>
            {label}
          </Text>
        </VStack>
      </HStack>
    </Button>
  );
};
