/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import BookmarkEditor from '../bookmarkEditor/bookmarkEditor.component';
import Modal from '../Modal/Modal.component';

export const SiimplAdd = (): JSX.Element => {
  const [showBookmarkCreator, setShowBookmarkCreator] = useState(false);

  return (
    <>
      <Button variant='primary' onClick={() => setShowBookmarkCreator(true)}>
        Add Bookmark
      </Button>

      <Modal show={showBookmarkCreator} onClose={() => setShowBookmarkCreator(false)} maxWidth={460}>
        <BookmarkEditor isAdd={true} close={() => setShowBookmarkCreator(false)} />
      </Modal>
    </>
  );
};
