import { ChakraProvider, defaultSystem } from '@chakra-ui/react';
import { ErrorBoundary } from './Components/ErrorBoundary/ErrorBoundary.component';
import { ToastContainer } from 'react-toastify';
import ApplicationContainer from './ApplicationContainer';
import UserProvider from './providers/UserProvider.provider';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import './index.css'

function App(): JSX.Element {
  return (
    <ChakraProvider value={defaultSystem}>
      <ErrorBoundary>
        <UserProvider>
          <ToastContainer />
          <ApplicationContainer />
        </UserProvider>
      </ErrorBoundary>
    </ChakraProvider>
  );
}

export default App;
