import { Button, Input as ChakraInput, VStack } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  background-color: black;
`;

export const Container = styled.div`
  max-width: 1280px;
  margin: auto;
`;

export const AuthContainer = styled(VStack)`
  width: 300px;
  text-align: left;

  h5 {
    background-image: linear-gradient(135deg, #698efb, #7afec9);
    color: #698efb;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
`;

export const Input = styled(ChakraInput)`
  height: 40px;
  width: 100%;
  border-radius: 8px;
  padding: 8px;
`;

export const LoginButton = styled(Button)`
  background-image: linear-gradient(135deg, #698efb, #7afec9);
  width: 100%;
  height: 40px;
  border-radius: 8px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
`;
