/*global chrome*/
import firebase from 'firebase/compat/app';
import {
  getAuth,
  signInWithPopup,
  OAuthProvider,
  GoogleAuthProvider,
  User,
  signInWithEmailAndPassword,
  EmailAuthProvider,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DBURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MSGSENDID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENT,
};

firebase.initializeApp(firebaseConfig);
export const auth = getAuth();
export const firestore = firebase.firestore();
export const db = firebase.firestore();

const createUserProfile = async (uid: string, email: string, displayName: string) => {
  const accessToken = await getToken();

  const userData = {
    UserGUID: uid,
    DisplayName: displayName,
    UserEmail: email,
  };

  fetch('/apiv2/addUser', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(userData),
  })
    .then((response) => {
      if (response.status !== 200) {
        console.error('error');
      }
      return response.json();
    })
    .catch((err) => {
      console.error(err);
      alert(err);
    });
};

export const sendToExtension = (authResult: any, provider: string) => {
  const isExtensionLogin = window.location.pathname === '/extension-login';
  if (!isExtensionLogin) {
    return;
  }

  try {
    let credential;

    switch (provider) {
      case 'email':
        credential = authResult;
        break;

      case 'google.com':
        credential = JSON.parse(JSON.stringify(authResult));
        break;

      case 'apple.com':
        credential = JSON.parse(JSON.stringify(authResult));
    }

    window.postMessage({ type: 'auth', message: { credential, provider } });
  } catch (err) {
    console.error(err);
  }
};

export const signInWithGoogle = async () => {
  return new Promise((resolve, reject) => {
    const providerName = 'google.com';
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(function (result) {
        const user = result.user;
        const displayName = user.displayName;
        generateUserDocument(user, { displayName });
        sendToExtension(result, providerName);
        resolve('success');
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
};

export const signInWithApple = async () => {
  return new Promise((resolve, reject) => {
    const providerName = 'apple.com';
    const provider = new OAuthProvider(providerName);

    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        const displayName = user.displayName;
        generateUserDocument(user, { displayName });
        sendToExtension(result, providerName);
        resolve(result);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
};

export const signInWithEmail = (data: { email: string; password: string }) => {
  const { email, password } = data;

  return new Promise((resolve, reject) => {
    const providerName = 'email';
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const authCredential = EmailAuthProvider.credential(email, password);
        const user = userCredential.user;
        sendToExtension(authCredential, providerName);
        resolve(user);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createUserWithEmailAndPasswordHandler = async (data: { email: string; password: string }) => {
  const { email, password } = data;
  return new Promise((resolve, reject) => {
    const auth = getAuth();

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const providerName = 'email';
        const authCredential = EmailAuthProvider.credential(email, password);

        sendToExtension(authCredential, providerName);

        generateUserDocument(userCredential.user, {});
        resolve(userCredential.user);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const generateUserDocument = async (user: User, additionalData: any) => {
  if (!user) return;

  const auth = getAuth();
  const currentUser = auth.currentUser;
  const userRef = firestore.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    const { displayName } = additionalData;
    const isFirstLogin = true;
    try {
      await userRef.set({
        displayName: displayName || currentUser?.email,
        isFirstLogin,
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      createUserProfile(user.uid, user.email, displayName);
    } catch (error) {
      console.error('Error creating user document', error);
    }
  } else {
    const { userBio, displayName, photoURL, siimplSauce } = additionalData;
    try {
      await userRef.update({
        displayName,
        photoURL,
        userBio,
        siimplSauce,
      });
    } catch (error) {
      console.error('Error creating user document', error);
    }
  }
  return getUserDocument(user.uid);
};

export const getUserDocument = async (uid: string) => {
  if (!uid) return null;
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();
    return {
      uid,
      ...userDocument.data(),
    };
  } catch (error) {
    console.error('Error fetching user', error);
  }
};

export const getToken = () => {
  const auth = getAuth();
  return auth?.currentUser
    ?.getIdToken(true)
    .then(function (idToken) {
      return idToken;
    })
    .catch(console.error);
};

export const updateSiteTheme = async (theme: string) => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) return;
  const userRef = firestore.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    try {
      await userRef.set({
        theme,
      });
    } catch (error) {
      console.error('Error creating user document', error);
    }
  } else {
    try {
      await userRef.update({
        theme,
      });
    } catch (error) {
      console.error('Error creating user document', error);
    }
  }
};

export const updateProfilePic = async (user: User, photoURL: string) => {
  if (!user) return;
  const userRef = firestore.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    try {
      await userRef.set({
        photoURL,
      });
    } catch (error) {
      console.error('Error creating user document', error);
    }
  } else {
    try {
      await userRef.update({
        photoURL,
      });
    } catch (error) {
      console.error('Error creating user document', error);
    }
  }
  return getUserDocument(user.uid);
};
