import React, { useState, useEffect, Key } from 'react';
import moment from 'moment';
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import { Grid, GridItem, HStack, Input, Stack, Text } from '@chakra-ui/react';
import { Tooltip } from 'react-tooltip';

interface TabItemType {
  tab: string;
  url: string;
  favIconUrl: string;
  title: string;
}
interface TabCollectionType {
  TabCollectionId: string;
  TabCollectionName: string;
  DateCreated: string;
  TabJSON: string;
}

import { fetchAPI, getToken } from '../../util/data/fetchAPI.util';
import Modal from '../../Components/Modal/Modal.component';
import { EditCollectionLabel, NoTabIcon, TabIcon, TabLink, Wrapper } from './tabs.style';
import { LoadingLogo } from '../../Components/loadingLogo/LoadingLogo.component';
import { removeDateTime } from '../../util/data/ui.util';

export default function Tabs() {
  const [isLoading, setIsLoading] = useState(true);
  const [tabCollections, setTabCollections] = useState<TabCollectionType[]>([]);
  const [selectedTabCollection, setSelectedTabCollection] = useState<TabCollectionType | null>(null);

  useEffect(() => {
    getTabCollections();
  }, []);

  const getTabCollections = async () => {
    const accessToken = await getToken();

    fetch(`/apiv2/getTabCollections`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.error('error');
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setTabCollections(
          data.sort(
            (a: { DateCreated: string | number | Date }, b: { DateCreated: string | number | Date }) =>
              new Date(b.DateCreated).valueOf() - new Date(a.DateCreated).valueOf(),
          ),
        );
        setIsLoading(false);
      });
  };

  const moveToTrash = async (TabCollectionId: string) => {
    const accessToken = await getToken();

    fetch('/apiv2/removeTabCollection', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        collectionId: TabCollectionId,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.error('error');
        }
        return response.json();
      })
      .then(() => {
        setTabCollections((prevTabCollections) =>
          prevTabCollections.filter((el: { TabCollectionId: string }) => el.TabCollectionId !== TabCollectionId),
        );
      })
      .catch((err) => console.error(err));
  };

  const updateTabCollection = async () => {
    const updatedTab = {
      ...selectedTabCollection,
      TabCollectionName: selectedTabCollection?.TabCollectionName,
    };

    fetchAPI
      .post('/apiv2/updateTabCollection', {
        body: updatedTab,
      })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        setTabCollections((prevState) =>
          prevState.map((tabCollection) =>
            tabCollection.TabCollectionId === selectedTabCollection?.TabCollectionId ? updatedTab : tabCollection,
          ),
        );
        setSelectedTabCollection(null);
      })
      .catch((err) => console.error(err));
  };

  const getTabIcons = (tabCollection: any) => {
    return JSON.parse(tabCollection?.TabJSON ?? '{}');
  };

  const handleCollectionNameChange = (value: string) => {
    const updatedTabCollection = { ...selectedTabCollection, TabCollectionName: value } as TabCollectionType;
    setSelectedTabCollection(updatedTabCollection);
  };

  const loadImageWithPlaceholder = (props: any) => {
    const imgElement = document.querySelector(`#${props.target.id}`) as HTMLImageElement;

    if (!imgElement) return;

    imgElement.onerror = function () {
      imgElement.src = 'https://res.cloudinary.com/siimpl/image/upload/v1738246101/placeholder-image_ejn765.png';
      imgElement.onerror = null; // Prevent infinite loop in case placeholder also fails
    };
  };

  if (isLoading) {
    return (
      <Stack height='75vh' justifyContent='center' alignItems='center'>
        <LoadingLogo />
      </Stack>
    );
  }

  return (
    <Wrapper>
      <Text as='h4' margin='0'>
        Tab Collections
      </Text>

      <Grid templateColumns='repeat(auto-fill, minmax(300px, 1fr))' gap={6} marginTop={6}>
        {tabCollections.map((tabCollection, index) => (
          <GridItem
            style={{
              borderRadius: 12,
              padding: 12,
              background: '#15191f !important',
              outline: 'solid 1px rgba(31, 41, 55, 0.9490196078)',
            }}
            key={index}
          >
            <HStack justifyContent='space-between' marginBottom='12px'>
              <span className='date'>
                <small>{moment(tabCollection.DateCreated).format('ll')}</small>
              </span>
              <Dropdown as={ButtonGroup} alignRight>
                <Dropdown.Toggle variant='link' className='text-muted'>
                  <svg
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    stroke='currentColor'
                    strokeWidth='2'
                    fill='none'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    <circle cx='12' cy='12' r='1'></circle>
                    <circle cx='19' cy='12' r='1'></circle>
                    <circle cx='5' cy='12' r='1'></circle>
                  </svg>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey='4' onClick={() => setSelectedTabCollection(tabCollection)}>
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item eventKey='5' onClick={() => moveToTrash(tabCollection.TabCollectionId)}>
                    Remove
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </HStack>
            <Text>{removeDateTime(tabCollection.TabCollectionName)}</Text>

            <HStack flexWrap='wrap'>
              {getTabIcons(tabCollection).map((tab: TabItemType, index: Key) => (
                <TabLink
                  href={tab.url}
                  target='_blank'
                  rel='noreferrer'
                  key={index}
                  data-tooltip-id='my-tooltip'
                  data-tooltip-position-strategy='fixed'
                  data-tooltip-content={tab.title}
                  data-tooltip-place='top'
                >
                  {tab.favIconUrl ? (
                    <TabIcon
                      className='fav-icon'
                      src={tab.favIconUrl}
                      id={`icon-${index}`}
                      onLoad={loadImageWithPlaceholder}
                    />
                  ) : (
                    <NoTabIcon>{tab.title?.[0] ?? '?'}</NoTabIcon>
                  )}
                </TabLink>
              ))}
            </HStack>
            <Tooltip id='my-tooltip' style={{ zIndex: 10000 }} />
          </GridItem>
        ))}
      </Grid>

      <Modal show={!!selectedTabCollection} onClose={() => setSelectedTabCollection(null)} maxWidth={460}>
        <div id='edit-tab-collection' style={{ display: 'flex', flexDirection: 'column' }}>
          <h4 className='h4 mr-auto mb-3'>Edit Tab Collection</h4>
          <EditCollectionLabel>Tab Collection Name</EditCollectionLabel>
          <Input
            defaultValue={selectedTabCollection?.TabCollectionName}
            type='text'
            placeholder='Tab Collection Name'
            value={selectedTabCollection?.TabCollectionName}
            onChange={(e) => handleCollectionNameChange(e.target.value)}
            spellCheck={false}
            autoComplete='off'
            marginBottom='40px'
          />
          <Button style={{ alignSelf: 'flex-end' }} onClick={updateTabCollection}>
            Update
          </Button>
        </div>
      </Modal>
    </Wrapper>
  );
}
