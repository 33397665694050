import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding: 16px 24px;
`;

export const TabLink = styled.a`
  display: flex;
  margin-bottom: 10px;
  height: 50px;
  width: 50px;
  border-radius: 8px;
  overflow: hidden;
`;

export const TabIcon = styled.img`
  object-fit: cover;
  background-color: white;
  width: 100%;
`;

export const NoTabIcon = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: black;
  text-transform: uppercase;
`;

export const EditCollectionLabel = styled.label`
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: #b1b5c3;
`;
