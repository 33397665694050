import { ActiveBorder, Button } from '../../styles/button.style';
import { Header, Wrapper } from './bookmarks.style';
import { Box, Button as ChakraButton, HStack, Link, Stack, Text, VStack } from '@chakra-ui/react';
import BookmarkMasonry from '../../Elements/BookmarkMasonry';
import { useContext, useEffect, useMemo, useState } from 'react';
import { DataContext } from '../../providers/dataProvider';
import { getToken } from '../../util/auth/firebase';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import sortByKey from '../../util/data/sort.util';
import Modal from '../../Components/Modal/Modal.component';
import CollectionEditor from '../../Components/collectionEditor/collectionEditor.component';
import { getCollectionFromURL } from '../../util/navigation/navigation.util';
import { CollectionTypes } from '../../types/CollectionTypes';
import { fetchAPI } from '../../util/data/fetchAPI.util';
import { generateAPIPath } from '../../util/helpers.util';
import { LoadingLogo } from '../../Components/loadingLogo/LoadingLogo.component';
import { getCollectionName } from '../../util/data/ui.util';
import { collectionEditDisabledPaths } from '../../defintions/collection.defintions';
import { Route } from '../../defintions/routes.defintions';
import EmptyTrash from './components/emptyTrash/emptyTrash';
import { MultiSelect } from '../../Components/MultiSelect/MultiSelect.component';
import { useMediaQuery } from 'react-responsive';
import NoBookmarks from './components/noBookmarks/noBookmarks.component';
import { HiOutlineShare } from 'react-icons/hi';
import { HiOutlinePencil } from 'react-icons/hi';
import CollectionSharer from '../../Components/collectionSharer/collectionSharer.component';
import { config } from '../../config/config';

export const BookmarksV2 = () => {
  const { collectionBookmarks, userCollections, sharedCollections, setCollectionBookmarks } = useContext(DataContext);

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [selectedBookmarks, setSelectedBookmarks] = useState<string[]>([]);
  const [activeSort, setActiveSort] = useState('date');
  const [showCollectionCreator, setShowCollectionCreator] = useState(false);
  const [showCollectionSharer, setShowCollectionSharer] = useState(false);

  const activeCollectionId = getCollectionFromURL() as string;
  const collection = useMemo(() => {
    const userCollection = userCollections.find(
      (collection: { CollectionId: string }) => collection.CollectionId === activeCollectionId,
    );

    if (userCollection) {
      return userCollection as CollectionTypes;
    }

    return sharedCollections.find(
      (collection: { CollectionId: string }) => collection.CollectionId === activeCollectionId,
    ) as unknown as CollectionTypes;
  }, [activeCollectionId, userCollections, sharedCollections]);

  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });

  useEffect(() => {
    setSelectedBookmarks([]);
    setIsLoading(true);

    const url = generateAPIPath(activeCollectionId);

    fetchAPI
      .get(url, {
        headers: {
          collectionid: activeCollectionId,
        },
      })
      .then((data) => {
        handleSortChange('date', data);
      })
      .finally(() => setIsLoading(false));
  }, [activeCollectionId]);

  const images = useMemo(
    () =>
      collectionBookmarks.filter((b: { BookmarkType: string }) => b.BookmarkType === 'image') as {
        BookmarkImage: string;
      }[],
    [collectionBookmarks],
  );

  const openImageLightbox = (Id: string) => {
    const obj = images.findIndex((el: any) => el.Id === Id);
    setPhotoIndex(obj);
    setIsOpen(true);
  };

  const addToSelected = (bookmarkId: string) => {
    if (selectedBookmarks.includes(bookmarkId)) {
      const selectedBookmarkArr = selectedBookmarks.filter((el) => el !== bookmarkId);
      setSelectedBookmarks(selectedBookmarkArr);
      return;
    }

    const selectedBookmarkArr = [bookmarkId, ...selectedBookmarks];
    setSelectedBookmarks(selectedBookmarkArr);
  };

  const moveToTrash = async (bookmarkId: string) => {
    const accessToken = await getToken();

    fetch('/apiv2/removeBookmark', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        id: bookmarkId,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.error('error');
        }
        return response.json();
      })
      .then(() => {
        setCollectionBookmarks(collectionBookmarks.filter((el: { Id: string }) => el.Id !== bookmarkId));
      });
  };

  const handleSortChange = async (order: string, data?: any[]) => {
    let sortedBookmarks;
    const bookmarks = data ?? collectionBookmarks;

    switch (order) {
      case 'date':
        sortedBookmarks = sortByKey(bookmarks, 'DateCreated', 'desc');
        break;

      case 'name':
        sortedBookmarks = sortByKey(bookmarks, 'BookmarkName', 'asc');
        break;

      case 'url':
        sortedBookmarks = sortByKey(bookmarks, 'BookmarkURL', 'asc');
        break;

      default:
        sortedBookmarks = sortByKey(bookmarks, 'DateCreated', 'desc');
    }

    setActiveSort(order);
    setCollectionBookmarks(sortedBookmarks);
  };

  const hideEditButton = collectionEditDisabledPaths.includes(location.pathname as Route) || collection?.isOwnedByOther;

  const sortOptions = ['date', 'name', 'url'];

  if (isLoading) {
    return (
      <Stack height='75vh' justifyContent='center' alignItems='center'>
        <LoadingLogo />
      </Stack>
    );
  }

  return (
    <Wrapper>
      <Header>
        <HStack gap={4}>
          <VStack alignItems='flex-start' gap={0}>
            <Text as='h4' margin='0'>
              {getCollectionName(collection?.CollectionName)}
            </Text>
            {collection?.isOwnedByOther && (
              <Text as='span' fontSize='11px'>
                Owned by {collection?.UserEmail}
              </Text>
            )}
          </VStack>

          {!hideEditButton && (
            <ChakraButton
              variant='plain'
              cursor='pointer'
              p='0'
              minW='unset'
              onClick={() => setShowCollectionCreator(true)}
            >
              <HiOutlinePencil />
            </ChakraButton>
          )}
          {collection?.isOwn && (
            <ChakraButton
              variant='plain'
              cursor='pointer'
              p='0'
              minW='unset'
              onClick={() => setShowCollectionSharer(true)}
            >
              <HiOutlineShare />
            </ChakraButton>
          )}
        </HStack>

        <HStack marginLeft='auto' className='sort-container' alignItems='flex-start'>
          {activeCollectionId === 'Trash' && <EmptyTrash onTrashEmpty={() => setCollectionBookmarks([])} />}
          {!isMobile && <Text margin='0'>Sort:</Text>}

          {sortOptions.map((option, index) => (
            <Button
              key={index}
              variant='plain'
              height='30px'
              textTransform='capitalize'
              onClick={() => handleSortChange(option)}
            >
              {option}
              <ActiveBorder active={activeSort === option} />
            </Button>
          ))}
        </HStack>
      </Header>

      {collection?.ShareId && (
        <Box>
          <Text>
            This collection is currently shared and can be found at{' '}
            <Link href={`${config.baseUrl}/p/${collection.ShareId}`} target='_blank'>
              {`${config.baseUrl}/p/${collection.ShareId}`}
            </Link>
          </Text>
        </Box>
      )}

      {!collectionBookmarks?.length ? (
        <NoBookmarks />
      ) : (
        <BookmarkMasonry
          openImageLightbox={openImageLightbox}
          collectionBookmarks={collectionBookmarks}
          addToSelected={(id: string) => addToSelected(id)}
          moveToTrash={(id: number) => moveToTrash(id as unknown as string)}
          selectedBookmarks={selectedBookmarks}
          selectedCollection={collection}
        />
      )}

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].BookmarkImage}
          nextSrc={images[(photoIndex + 1) % images?.length].BookmarkImage}
          prevSrc={images[(photoIndex + images?.length - 1) % images?.length].BookmarkImage}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex - 1) % images?.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images?.length)}
        />
      )}

      <Modal show={showCollectionCreator} onClose={() => setShowCollectionCreator(false)}>
        <CollectionEditor isAdd={false} close={() => setShowCollectionCreator(false)} />
      </Modal>

      <Modal show={showCollectionSharer} onClose={() => setShowCollectionSharer(false)} maxHeight='90vh'>
        <CollectionSharer selectedCollection={collection} onClose={() => setShowCollectionSharer(false)} />
      </Modal>

      {selectedBookmarks.length > 0 && (
        <MultiSelect
          selectedBookmarks={selectedBookmarks}
          setSelectedBookmarks={(selectedBookmarkIds) => setSelectedBookmarks(selectedBookmarkIds)}
          isEditable={1}
          activeCollection={activeCollectionId}
        />
      )}
    </Wrapper>
  );
};
