import React, { useEffect } from 'react';
import { HeroTitle } from '../homePage/homePage.style';
import { Button, Text } from '@chakra-ui/react';
import { Wrapper } from './noAccessPage.style';
import { Link } from 'react-router-dom';

export const NoAccessPage = () => {
  const [isLoaded, setIsLoaded] = React.useState(false);

  const handleClick = () => {
    localStorage.setItem('redirect', window.location.pathname);
  };

  useEffect(() => {
    // Give the app time to load the data when logging out
    setTimeout(() => {
      setIsLoaded(true);
    }, 1000);
  });

  if (!isLoaded) {
    return null;
  }

  return (
    <Wrapper>
      <HeroTitle>Oops</HeroTitle>
      <Text fontSize='32px'>This Page Isn’t Available 😕</Text>
      <Text fontSize='18px'>
        It looks like the page you’re trying to access doesn’t exist or may have moved. If you were invited to a
        collection or trying to access a collection, try logging into your Siimpl account to see if it’s available. 🔹
        Log In Here If you need help, feel free to contact support. 🚀
      </Text>
      <Link to='/app/login' onClick={handleClick}>
        <Button background='#289cff'>Login</Button>
      </Link>
    </Wrapper>
  );
};
