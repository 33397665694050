import styled from '@emotion/styled';

// TODO: update below legacy styles to emotionJS
export const Wrapper = styled.div`
  .edit-header {
    display: flex;

    .h2 {
      font-size: 48px;
      line-height: 1.16667;
      letter-spacing: -0.02em;
    }
  }

  .save-button {
    flex-shrink: 0;
    margin-left: 24px;
    background-color: dodgerblue !important;
    color: white !important;
    font-size: 16px !important;
  }

  .save-button:hover {
    background-color: blue !important;
  }

  .button-stroke {
    background: none;
    box-shadow: 0 0 0 2px #e6e8ec inset;
    color: #23262f;
  }

  .button-small {
    height: 40px;
    border-radius: 20px;
    padding: 0 16px;
    font-size: 12px;
  }

  .editor-title {
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    margin: 0px;
  }

  .editor-note {
    margin-top: 4px;
    font-size: 12px;
    line-height: 1.66667;
  }

  .file-uploader {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 182px;
    margin-top: 16px;
    border-radius: 16px;
    overflow: hidden;
    background: #f4f5f6;
    margin-bottom: 50px;
  }

  .input-wrap {
    margin-bottom: 5px;
  }

  .input-wrap:last-of-type {
    margin-bottom: 15px;
  }

  label {
    margin-bottom: 0px;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    color: #b1b5c3;
  }

  input,
  .input {
    width: 100%;
    border-radius: 12px;
    border: 2px solid #e6e8ec;
    background: none;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 1.71429;
    font-weight: 700;
    color: #23262f;
    transition: border-color 0.2s;
    height: 40px;
    padding: 0 14px;
    box-shadow: none !important;
    outline: none !important;
  }

  .bookmark-tag {
    font-weight: 500;
    border-radius: 5px;
    float: left;
    border: solid 2px #45b26b;
    padding: 0 10px;
    color: #45b26b;
    margin-right: 15px;
  }

  hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .extra-option {
    display: flex;
    align-items: flex-start;
    margin-bottom: 18px;

    .extra-box {
      flex-grow: 1;
    }

    .switch {
      flex-shrink: 0;
      margin-left: 24px;
    }
  }

  .collection-selector-wrapper {
    background: #f4f5f6;
    height: 182px;
    padding: 8px 0;
    border-radius: 8px;
    margin-bottom: 12px;
    font-size: 13px;
    -webkit-transform: translate3d(0, 0, 0);

    .collection-selector {
      overflow: auto;
      height: 164px;

      .collection-selector-item {
        cursor: pointer;
        padding: 7.5px 20px;
      }

      .collection-selector-item:hover,
      .collection-selector-item.active {
        background: #cfe1f4;
      }
    }

    @media (max-width: 1136px) {
      width: unset;

      .edit-wrapper {
        flex-basis: 100%;
        width: 100%;
        padding: 0;
      }
    }
  }
`;
