/* eslint-disable @typescript-eslint/no-explicit-any */
import 'firebase/auth';
import { auth } from '../auth/firebase';

export async function getToken(): Promise<any> {
  const { currentUser } = auth;
  return await currentUser
    ?.getIdToken(true)
    .then((idToken) => idToken)
    .catch((error) => {
      console.error(error);
    });
}

async function apiOptions(method: string, options: { headers?: any; body?: any }): Promise<any> {
  const accessToken = await getToken();
  if (!options) {
    options = {};
  }

  if (!options.headers) {
    options.headers = {};
  }
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  options.headers.Authorization = `Bearer ${accessToken}`;

  const optionResponse = {
    method,
    headers: options.headers,
  };

  if (method === 'POST') {
    options.headers['Content-Type'] = 'application/json';
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    optionResponse.body = JSON.stringify(options.body);
  }

  return optionResponse;
}

export const fetchAPI = {
  get,
  post,
};

async function get(url: RequestInfo, options?: any): Promise<any> {
  const requestOptions = await apiOptions('GET', options);
  return await fetch(url, requestOptions).then(handleResponse);
}

async function post(url: RequestInfo, options?: any): Promise<any> {
  const requestOptions = await apiOptions('POST', options);
  return await fetch(url, requestOptions).then(handleResponse);
}

async function handleResponse(response: { status: number; json: () => any }): Promise<any> {
  if (response.status !== 200) {
    console.error('error');
  }
  return response.json();
}
