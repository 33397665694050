import styled from '@emotion/styled';

// TODO: update below legacy styles to emotionJS
export const Wrapper = styled.div`
  .collection-container {
    display: flex;
    align-items: flex-start;
    min-height: 95vh;
    margin: 0px auto;
    padding-top: 60px;
  }

  .collection-title {
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    margin: 0px;
  }

  .collection-note {
    margin-top: 4px;
    font-size: 12px;
    line-height: 1.66667;
  }

  .nested-warning {
    background: #d63100;
    border-radius: 5px;
    padding: 5px;
    color: #ffffff;
  }

  .input-wrap {
    margin-bottom: 16px;
  }

  label {
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    color: #b1b5c3;
  }

  input,
  .input {
    width: 100%;
    border-radius: 12px;
    border: 2px solid #e6e8ec;
    background: none;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 1.71429;
    font-weight: 700;
    color: #23262f;
    transition: border-color 0.2s;
    height: 48px;
    padding: 0 14px;
    box-shadow: none !important;
    outline: none !important;
  }

  .save-button {
    flex-shrink: 0;
    margin-left: 24px;
    background-color: dodgerblue !important;
    color: white !important;
    font-size: 16px !important;
  }

  .save-button:hover {
    background-color: blue !important;
  }

  .collection-selector-wrapper {
    background: #f4f5f6;
    height: 182px;
    padding: 8px 0;
    border-radius: 8px;
    margin-bottom: 12px;
    font-size: 13px;
    -webkit-transform: translate3d(0, 0, 0);

    .collection-selector {
      overflow: auto;
      height: 164px;

      .collection-selector-item {
        cursor: pointer;
        padding: 4px 8px;
      }

      .collection-selector-item:hover,
      .collection-selector-item.active {
        background: #cfe1f4;
      }
    }
  }

  .collection-wrapper {
    flex: 0 0 calc(100% - 450px);
    width: calc(100% - 450px);
    padding-right: 128px;

    .collection-header {
      display: flex;
      margin-bottom: 40px;

      .h2 {
        font-size: 48px;
        line-height: 1.16667;
        letter-spacing: -0.02em;
      }
    }

    .button-stroke {
      background: none;
      box-shadow: 0 0 0 2px #e6e8ec inset;
      color: #23262f;
    }

    [class^='button'] {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-family: 'DM Sans', sans-serif;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      transition: all 0.2s;
      cursor: pointer;
    }

    .button-small {
      height: 40px;
      border-radius: 20px;
      padding: 0 16px;
      font-size: 12px;
    }

    .file-uploader {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 182px;
      margin-top: 16px;
      border-radius: 16px;
      overflow: hidden;
      background: #f4f5f6;
      margin-bottom: 50px;
    }

    .bookmark-tag {
      font-weight: 500;
      border-radius: 5px;
      float: left;
      border: solid 2px #45b26b;
      padding: 0 10px;
      color: #45b26b;
      margin-right: 15px;
    }

    hr {
      margin-top: 60px;
      margin-bottom: 35px;
    }

    .extra-option {
      display: flex;
      align-items: flex-start;
      margin-bottom: 18px;

      .extra-box {
        flex-grow: 1;
      }

      .switch {
        flex-shrink: 0;
        margin-left: 24px;
      }
    }
  }

  .sharer {
    width: 450px;
    position: relative;
    margin-bottom: 20px;

    .collection-header {
      display: flex;
      margin-bottom: 40px;

      .h2 {
        font-size: 48px;
        line-height: 1.16667;
        letter-spacing: -0.02em;
      }
    }

    .email-item {
      .user-icon,
      .user-status-indicator {
        border: 2px solid #cacaca;
        border-radius: 50px;
        width: 25px;
        height: 25px;
        margin: auto 0 auto auto;

        svg {
          width: 20px;
          color: #cacaca;
        }
      }

      p {
        margin: 0px 15px;
        font-size: 15px !important;
        line-height: 1.8;
        font-weight: 700;
        text-transform: uppercase;
        color: #b1b5c3;
        width: 100%;
      }
    }

    .user-status-indicator {
      border-color: #39fb39 !important;
    }
  }

  @media (max-width: 1136px) {
    .collection-container {
      flex-direction: column-reverse;
    }

    .collection-wrapper {
      flex-basis: 100% !important;
      width: 100% !important;
      padding: 0 !important;
    }

    .sharer {
      width: 100% !important;
    }
  }
`;
