import React from 'react';
import {
  Container,
  CTA,
  CTAImage,
  CTAStack,
  CTAText,
  Details,
  DetailStack,
  Divider,
  DownloadGroup,
  Footer,
  FooterBG,
  Header,
  HeroBg,
  HeroHeading,
  HeroStack,
  HeroTitle,
  HeroWrapper,
  LoginButton,
  Logo,
  TopBar,
  Wrapper,
} from './homePage.style';
import { AppButton } from '../../components/ui/appButton/appButton';
import { Button, HStack, Link, Text, VStack } from '@chakra-ui/react';
import { GoPlusCircle } from 'react-icons/go';
import { Link as DomLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

export const HomePage = () => {
  const isMobile = useMediaQuery({
    query: '(max-width: 900px)',
  });

  return (
    <Wrapper>
      <Header>
        <Container>
          <TopBar>
            <Logo to='/'>
              Siimpl<span style={{ color: '#43a0d1' }}>.</span>
            </Logo>
            <DomLink to='/app/login'>
              <LoginButton variant='plain'>{isMobile ? 'Get organized' : 'Jump in and get organized'}</LoginButton>
            </DomLink>
          </TopBar>
        </Container>
      </Header>

      <HeroStack>
        <HeroBg />
        <HeroWrapper>
          <HeroHeading>
            Your Digital Life
            <br />
            <HeroTitle>Simplified</HeroTitle>
          </HeroHeading>
          <p>Save websites, images, and text you find online, then access from anywhere.</p>

          <DownloadGroup marginTop='30px' gap='32px'>
            <AppButton
              src='https://res.cloudinary.com/siimpl/image/upload/v1737657931/Apple_logo_black.svg_b4ejrx.png'
              header='Download on the'
              label='App Store'
              link='https://apps.apple.com/ca/app/siimpl-bookmarking/id1533377621'
            />
            <Link
              href='https://chrome.google.com/webstore/detail/siimpl-bookmarking/fjdfngkdfcmcibmbfinnbbeabbdjcghc'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src='https://res.cloudinary.com/siimpl/image/upload/v1737833864/Google_Chrome_icon__September_2014_.svg_p5q7z8.png'
                width='50px'
                height='50px'
              />
            </Link>
            <Link
              href='https://microsoftedge.microsoft.com/addons/detail/siimpl-bookmarking/gghgokkhgpbinokcfbojaeikcnjicbha'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src='https://res.cloudinary.com/siimpl/image/upload/v1737833942/Microsoft_Edge_logo__2019_ketxfy.png'
                width='50px'
                height='50px'
              />
            </Link>
            {/* <AppButton
              header='Get it on'
              src='https://res.cloudinary.com/siimpl/image/upload/v1737657583/99256cf85aebeff57fa10e5f773d3bd4_tnre79.png'
              label='Google Play'
              link='https://play.google.com/store/apps/details?id=com.siimpl'
            /> */}
          </DownloadGroup>
        </HeroWrapper>

        <img
          className='hero-image'
          src='https://res.cloudinary.com/siimpl/image/upload/v1737839583/Screenshot_2025-01-25_at_4.12.53_PM_raoeii.png'
        />
      </HeroStack>
      <Divider />
      <DetailStack>
        <Container>
          <Details gap='10.5rem'>
            <VStack width='50%' className='features'>
              <svg width='0' height='0'>
                <linearGradient id='blue-gradient' x1='100%' y1='100%' x2='0%' y2='0%'>
                  <stop stopColor='#698efb' offset='0%' />
                  <stop stopColor='#7afec9' offset='100%' />
                </linearGradient>
              </svg>
              <HStack flexWrap='wrap' justifyContent='center'>
                <VStack gap='4px' textAlign='center' width='32.3333%' marginBottom='26px'>
                  <GoPlusCircle size={40} style={{ fill: 'url(#blue-gradient)' }} />
                  <Text as='h5' margin={0} fontWeight={600}>
                    Easy
                  </Text>
                  <Text fontSize='12px'>Add Bookmarks & Images with a click of a button.</Text>
                </VStack>
                <VStack gap='4px' textAlign='center' width='32.3333%' marginBottom='26px'>
                  <GoPlusCircle size={40} style={{ fill: 'url(#blue-gradient)' }} />
                  <Text as='h5' margin={0} fontWeight={600}>
                    Collect
                  </Text>
                  <Text fontSize='12px'>Simply use our extension, web app or app to save it for later.</Text>
                </VStack>
                <VStack gap='4px' textAlign='center' width='32.3333%' marginBottom='26px'>
                  <GoPlusCircle size={40} style={{ fill: 'url(#blue-gradient)' }} />
                  <Text as='h5' margin={0} fontWeight={600}>
                    Share
                  </Text>
                  <Text fontSize='12px'>Share collections — allow edits or read only access.</Text>
                </VStack>
                <VStack gap='4px' textAlign='center' width='32.3333%' marginBottom='26px'>
                  <GoPlusCircle size={40} style={{ fill: 'url(#blue-gradient)' }} />
                  <Text as='h5' margin={0} fontWeight={600}>
                    Hidden
                  </Text>
                  <Text fontSize='12px'>Save websites, keep them hidden with a pin to access</Text>
                </VStack>
                <VStack gap='4px' textAlign='center' width='32.3333%' marginBottom='26px'>
                  <GoPlusCircle size={40} style={{ fill: 'url(#blue-gradient)' }} />
                  <Text as='h5' margin={0} fontWeight={600}>
                    Private
                  </Text>
                  <Text fontSize='12px'>No one can see your collections unless you share.</Text>
                </VStack>
                <VStack gap='4px' textAlign='center' width='32.3333%' marginBottom='26px'>
                  <GoPlusCircle size={40} style={{ fill: 'url(#blue-gradient)' }} />
                  <Text as='h5' margin={0} fontWeight={600}>
                    Anywhere
                  </Text>
                  <Text fontSize='12px'>Access content anywhere, phone, tablet or laptop.</Text>
                </VStack>
              </HStack>
            </VStack>
            <VStack width='50%' padding='0px 16px' textAlign='center' className='blurb'>
              <Text fontSize='28px' lineHeight='30px'>
                Our goal is to create a product and service that you’re satisfied with and use every day.
              </Text>
              <Text fontSize='16px' width='74%'>
                If there is something that can be improved or you have an idea then let us know
              </Text>
              <Button
                variant='plain'
                backgroundColor='white'
                color='black'
                borderRadius='12px'
                onClick={() => (window.location.href = 'mailto:hello@siimpl.co')}
              >
                Get in touch
              </Button>
            </VStack>
          </Details>
        </Container>
      </DetailStack>

      <CTAStack>
        <Container>
          <CTA>
            <CTAImage></CTAImage>
            <CTAText>
              <h5>
                Simplify your life with Siimpl Bookmarking – the easiest, free way to collect and organize your favorite
                links, images, and videos from anywhere. Sign up now and start bookmarking smarter!
              </h5>

              <DomLink to='/app/login'>
                <LoginButton variant='plain' marginTop='40px'>
                  Jump in and get organized
                </LoginButton>
              </DomLink>
            </CTAText>
          </CTA>
        </Container>
      </CTAStack>

      <Divider height={2} />
      <Footer>
        <FooterBG />
        <Container>
          <HStack alignItems={!isMobile ? 'flex-start' : 'center'}>
            {!isMobile && (
              <Logo to='/'>
                Siimpl<span style={{ color: '#43a0d1' }}>.</span>
              </Logo>
            )}

            <HStack margin={!isMobile ? 'unset' : 'auto'} marginLeft='auto' gap='40px' zIndex={100}>
              <VStack alignItems='flex-start' alignSelf='flex-start'>
                <h5>Product</h5>
                <Link href='/app/login' target='_blank'>
                  Web app
                </Link>
                <Link href='https://apps.apple.com/ca/app/siimpl-bookmarking/id1533377621' target='_blank'>
                  iPhone app
                </Link>
                <Link href='https://play.google.com/store/apps/details?id=com.siimpl' target='_blank'>
                  Android app
                </Link>
                <Link
                  href='https://chrome.google.com/webstore/detail/siimpl-bookmarking/fjdfngkdfcmcibmbfinnbbeabbdjcghc'
                  target='_blank'
                >
                  Chrome extension
                </Link>
                <Link
                  href='https://microsoftedge.microsoft.com/addons/detail/siimpl-bookmarking/gghgokkhgpbinokcfbojaeikcnjicbha'
                  target='_blank'
                >
                  Edge extension
                </Link>
              </VStack>
              <VStack alignItems='flex-start' alignSelf='flex-start'>
                <h5>Company</h5>
                <Link href='/terms' target='_blank'>
                  Terms of service
                </Link>
                <Link href='/privacy' target='_blank'>
                  Privacy Policy
                </Link>
                <Link href='/datadeletion' target='_blank'>
                  Data Deletion
                </Link>
                <Link href='mailto:hello@siimpl.co'>Contact</Link>
              </VStack>
            </HStack>
          </HStack>

          <Text marginTop='50px' textAlign='center' marginBottom='50px'>
            Copyright &copy; {new Date().getFullYear()} All rights reserved — Siimpl Software
          </Text>
        </Container>
      </Footer>
    </Wrapper>
  );
};
