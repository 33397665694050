import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 12px;
  width: 600px;
  max-width: 100vw;
  margin: auto;
  text-align: center;
  color: #fff;
  justify-content: center;
`;
