/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import SimpleBar from 'simplebar-react';

import { ButtonGroup, Dropdown } from 'react-bootstrap';

import Swal from 'sweetalert2';
import { fetchAPI } from '../../util/data/fetchAPI.util';
import { SearchBar } from '../../Components/Searchbar/Searchbar.style';
import Modal from '../../Components/Modal/Modal.component';
import { Box, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import { LoadingLogo } from '../../Components/loadingLogo/LoadingLogo.component';
import { SnippetItem, SnippetText, Wrapper } from './snippets.style';
import SnippetEditor from './components/snippetEditor/snippetEditor.component';
import { decodeHTML } from '../../util/data/html.util';

export default function Snippets(): JSX.Element {
  const [isLoading, setLoading] = useState(true);

  const [notes, setNotes] = useState<any[]>([]);
  const [query, setQuery] = useState('');

  const [selectedTextSnippet, setSelectedTextSnippet] = useState(null);

  useEffect(() => {
    getNotes();
  }, []);

  const getNotes = (): void => {
    fetchAPI
      .get('/apiv2/getNotes')
      .then((data) => {
        if (window.screen.width > 1136) {
          setSelectedTextSnippet(data[0]);
        }

        setNotes(data.sort((a: any, b: any) => new Date(b.DateCreated).valueOf() - new Date(a.DateCreated).valueOf()));
      })
      .finally(() => setLoading(false));
  };

  const moveToTrash = (selectedNoteId: string, index: number): void => {
    const customSwal = Swal.mixin({
      customClass: {
        confirmButton: 'btn yes-way',
        cancelButton: 'btn no-way',
      },
      buttonsStyling: false,
    });

    customSwal
      .fire({
        title: 'Are you sure you want to remove this text snippet?',
        text: 'This can not be undone',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove it!',
        cancelButtonText: 'No, get me outta here!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          fetchAPI
            .post('/apiv2/removeNote', {
              headers: {
                noteId: selectedNoteId,
              },
            })
            .then(() => {
              const updatedArr = notes.filter((el) => el.Id !== selectedNoteId);
              setNotes(updatedArr);
              setSelectedTextSnippet(updatedArr[index - 1] || updatedArr[index + 1]);
            })
            .catch(console.log);
        }
      })
      .catch(console.error);
  };

  const updateTextSnippet = (snippet: any): void => {
    const notesArr = notes;
    const obj = notesArr.findIndex((el) => el.Id === snippet.Id);
    notesArr[obj].BookmarkName = snippet.BookmarkName;
    notesArr[obj].BookmarkBlurb = snippet.BookmarkBlurb;

    setNotes((prevState) => prevState.map((item) => (item.Id === snippet.Id ? notesArr[obj] : { ...item })));
  };

  const snippetResults = notes.filter(
    (note) =>
      note?.BookmarkName?.toLowerCase().includes(query?.toLowerCase()) ||
      note?.BookmarkBlurb?.toLowerCase().includes(query?.toLowerCase()),
  );

  if (isLoading) {
    return (
      <Stack height='75vh' justifyContent='center' alignItems='center'>
        <LoadingLogo />
      </Stack>
    );
  }

  return (
    <Wrapper>
      <HStack alignItems='flex-start' gap={8}>
        <Box width='300px'>
          <SearchBar
            type='search'
            placeholder='Search snippets...'
            value={query}
            onChange={(e: { target: { value: string } }) => setQuery(e.target.value)}
          />

          <SimpleBar style={{ maxHeight: 'calc(100vh - 140px)' }} autoHide>
            <VStack gap={4} paddingY={8}>
              {snippetResults.map((item, index) => (
                <Box key={index}>
                  <SnippetItem active={selectedTextSnippet === item} onClick={() => setSelectedTextSnippet(item)}>
                    <HStack justifyContent='space-between' marginBottom='12px'>
                      <span className='date'>
                        <small>{moment(item.DateCreated).format('ll')}</small>
                      </span>
                      <Dropdown as={ButtonGroup} alignRight>
                        <Dropdown.Toggle variant='link' className='text-muted'>
                          <svg
                            viewBox='0 0 24 24'
                            width='24'
                            height='24'
                            stroke='currentColor'
                            strokeWidth='2'
                            fill='none'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          >
                            <circle cx='12' cy='12' r='1'></circle>
                            <circle cx='19' cy='12' r='1'></circle>
                            <circle cx='5' cy='12' r='1'></circle>
                          </svg>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item eventKey='5' onClick={() => moveToTrash(item.Id, index)}>
                            Remove
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </HStack>
                    <Text as='h6'>{item.BookmarkName}</Text>
                    <SnippetText>{decodeHTML(item.BookmarkBlurb)}</SnippetText>
                  </SnippetItem>
                </Box>
              ))}
            </VStack>
          </SimpleBar>
        </Box>

        <SnippetEditor
          selectedTextSnippet={selectedTextSnippet}
          updateTextSnippet={(snippet: any) => updateTextSnippet(snippet)}
        />
      </HStack>

      <Modal
        className='mobile-text-snippet'
        show={!!selectedTextSnippet}
        onClose={() => {
          setSelectedTextSnippet(null);
        }}
        maxWidth={600}
      >
        <SnippetEditor
          selectedTextSnippet={selectedTextSnippet}
          updateTextSnippet={(snippet: any) => updateTextSnippet(snippet)}
        />
      </Modal>
    </Wrapper>
  );
}
