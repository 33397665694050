import { HStack } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  color: white;
  max-width: 1280px;
  margin: auto;
  padding: 40px 40px 40px;
  padding-top: 12px;

  .my-masonry-grid {
    max-width: unset;
  }
`;

export const Header = styled(HStack)`
  @media screen and (max-width: 600px) {
    flex-direction: column;

    button {
      margin-left: unset;
      margin-bottom: 20px;
    }
  }
`;

export const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 12px;
  width: 600px;
  max-width: 100vw;
  margin: auto;
  text-align: center;
  color: #fff;
  justify-content: center;
`;
