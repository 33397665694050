import Masonry from 'react-masonry-css';
import { BookmarkCard } from '../Components/BookmarkCard/BookmarkCard';
import './BookmarkMasonry.scss';
import { CollectionBookmarks } from '../defintions/Bookmark.defintion';

const breakpointColumnsObj = {
  default: 5,
  1800: 5,
  1600: 4,
  700: 2,
  100: 1,
};

const BookmarkList = (props: CollectionBookmarks) => {
  const { collectionBookmarks } = props;

  return (
    <div>
      <Masonry
        className='my-masonry-grid bookmark-grid'
        breakpointCols={breakpointColumnsObj}
        columnClassName='my-masonry-grid_column'
      >
        {collectionBookmarks.map((item: any) => (
          <BookmarkCard isGridLayout={false} bookmark={item} {...props} />
        ))}
      </Masonry>
    </div>
  );
};

export default BookmarkList;
