import React, { ReactNode, useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import { ModalContainer, ModalWrapper, Overlay } from './Modal.style';
import Portal from './Portal.component';

const Modal = ({
  className,
  children,
  onClose,
  show,
  maxWidth = 400,
  maxHeight,
  targetElement,
}: {
  className?: string;
  children: ReactNode;
  onClose: () => void;
  show: boolean;
  maxWidth?: number;
  maxHeight?: string;
  targetElement?: string;
}): JSX.Element => {
  useEffect(() => {
    if (show) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      document.querySelector('body').style.overflow = 'hidden';
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    document.querySelector('body').style.overflow = 'unset';
  }, [show]);

  if (!show) {
    return <div />;
  }

  const modalMaxHeight = maxHeight ?? `${window.screen.width > 1136 ? 80 : 70}vh`;

  return (
    <Portal target={targetElement}>
      <ModalWrapper className={className}>
        <Overlay onClick={onClose} />
        <ModalContainer className='modal-container' style={{ maxWidth: `${maxWidth}px` }}>
          <SimpleBar style={{ maxHeight: modalMaxHeight, padding: 20 }} autoHide>
            {children}
          </SimpleBar>
        </ModalContainer>
      </ModalWrapper>
    </Portal>
  );
};

export default Modal;
