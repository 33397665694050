import styled from '@emotion/styled';

export const Wrapper = styled.div`
  height: 64px;
  width: 610px;
  max-width: 90vw;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  background-color: #126dde12;
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);

  position: sticky;
  bottom: 30px;
  z-index: 100;
  border-radius: 12px;
  padding: 12px;
`;
