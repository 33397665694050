import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { UserContext } from './providers/UserProvider.provider';

import ApplicationWrapper from './Application/Application.view';

import App from './pages/website/App';
import Auth from './pages/auth';
import ExtensionAuth from './pages/auth/extensionLogin';
import { Public } from './pages/public/public';

function ApplicationContainer(): JSX.Element {
  const { user } = useContext(UserContext);

  return (
    <Router>
      <Route path='/b/public/:id' exact render={(props) => <Redirect to={`/p/${props.match?.params?.id}`} />} />

      {user && (
        <Switch>
          <Route path='/b/' render={() => <ApplicationWrapper />} />
        </Switch>
      )}

      <Switch>
        <Route path='/p/:id' exact render={() => <Public />} />
        <Route path='/extension-login' exact render={() => <ExtensionAuth />} />
        <Route path='/app/login' exact render={() => <Auth />} />
        <Route path='/' render={() => <App />} />
      </Switch>
    </Router>
  );
}

export default ApplicationContainer;
