import React, { useState } from 'react';
import { getToken } from '../../../../util/auth/firebase';
import { toast } from 'react-toastify';
import { Button } from '../../../../styles/button.style';

interface EmptyTrashProps {
  onTrashEmpty: () => void;
}

export default function EmptyTrash(props: EmptyTrashProps) {
  const { onTrashEmpty } = props;
  const [isEmptying, setIsEmptying] = useState(false);

  const EmptyTrash = async () => {
    setIsEmptying(true);
    const accessToken = await getToken();
    fetch('/apiv2/EmptyTrash', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.error('error');
        }
        return response.json();
      })
      .then(() => {
        toast(`😃 Trash Emptied`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        onTrashEmpty();
        setIsEmptying(false);
      });
  };

  return (
    <Button
      size='xs'
      backgroundColor='white'
      marginRight='30px'
      onClick={() => EmptyTrash()}
      style={{ color: 'black' }}
    >
      {isEmptying ? 'Emptying Trash' : 'Empty Trash'}
    </Button>
  );
}
