import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { AuthProps } from '../..';
import { AuthButton } from '../../components/authButton/authButton';
import { Input, LoginButton } from '../../auth.style';
import { useState } from 'react';
import { signInWithGoogle, signInWithApple, createUserWithEmailAndPasswordHandler } from '../../../../util/auth/firebase';

export const Register = (props: AuthProps) => {
  const { onAuthChange, onAuth } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string>('');

  const handleGoogleAuth = async () => {
    await signInWithGoogle().then(() => onAuth?.());
  };

  const handleAppleAuth = async () => {
    await signInWithApple().then(() => onAuth?.());
  };

  const handleCreateUser = async () => {
    createUserWithEmailAndPasswordHandler({ email, password })
      .then(() => onAuth?.())
      .catch(() => console.error('error creating user'));
  };

  return (
    <>
      <Text fontSize='26px' color='white'>
        Sign Up
      </Text>
      <AuthButton
        onClick={handleGoogleAuth}
        src='https://res.cloudinary.com/siimpl/image/upload/v1737657583/99256cf85aebeff57fa10e5f773d3bd4_tnre79.png'
        label='Google'
      />
      <AuthButton
        onClick={handleAppleAuth}
        src='https://res.cloudinary.com/siimpl/image/upload/v1737657931/Apple_logo_black.svg_b4ejrx.png'
        label='Apple'
      />

      <HStack justifyContent='center' alignItems='center' color='white'>
        <Box height='1px' background='#6c6c6c' width='110px' />
        <Text>or</Text>
        <Box height='1px' background='#6c6c6c' width='110px' />
      </HStack>
      <Input
        placeholder='Email'
        background='white'
        type='email'
        name='email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Input
        placeholder='Password'
        background='white'
        type='password'
        name='password'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      {error && <div style={{ color: '#b73c3c', fontSize: '12px', marginRight: 'auto' }}>{error}</div>}

      <LoginButton variant='plain' onClick={handleCreateUser}>
        Sign up
      </LoginButton>

      <Box height='1px' background='#6c6c6c' width='100%' />

      <Text color='white'>
        Already a member?{' '}
        <Button variant='plain' cursor='pointer' onClick={() => onAuthChange('login')}>
          <Text as='h5'>Log in</Text>
        </Button>
      </Text>
    </>
  );
};
