import React, { useEffect, useState, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';

import { htmlBrToNewline } from '../../../../util/helpers.util';
import { getToken } from '../../../../util/data/fetchAPI.util';
import { Button as ChakraButton, HStack, Input, Stack, Text } from '@chakra-ui/react';
import { LoadingLogo } from '../../../../Components/loadingLogo/LoadingLogo.component';
import { EditorContainer, Wrapper } from './snippetEditor.style';
import { Button } from '../../../../styles/button.style';
import { config } from './snippetEditor.defintions';
import { cleanSnippet } from './snippetEditor.utils';

export default function SnippetEditor(props: any): JSX.Element {
  const { selectedTextSnippet } = props;

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const [selectedNoteBlurb, setSelectedNoteBlurb] = useState('');
  const [selectedNoteTitle, setSelectedNoteTitle] = useState('');
  const [editorLoading, setEditorLoading] = useState(true);

  const editor = useRef(null);

  useEffect(() => {
    setLoading(true);
    setSelectedNoteTitle(selectedTextSnippet?.BookmarkName);
    setSelectedNoteBlurb(selectedTextSnippet?.BookmarkBlurb);
    setLoading(false);

    // This is required to update the editor with the new content and clean the html prior to showing
    setEditorLoading(true);
    setTimeout(() => {
      setEditorLoading(false);
    }, 500);
  }, [selectedTextSnippet]);

  const updateNote = async () => {
    setIsSaving(true);
    const accessToken = await getToken();

    const noteData = {
      Id: selectedTextSnippet?.Id,
      BookmarkName: selectedNoteTitle,
      BookmarkBlurb: encodeURIComponent(htmlBrToNewline(selectedNoteBlurb || '')),
    };

    fetch('/apiv2/updateNote', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(noteData),
    })
      .then((response) => {
        if (response.status !== 200) {
          console.error('error');
        }
        return response.json();
      })
      .then(() => {
        props.updateTextSnippet(noteData);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsSaving(false);
        setIsEditingTitle(false);
      });
  };

  if (isLoading) {
    return (
      <Stack height='75vh' width='100%' justifyContent='center' alignItems='center'>
        <LoadingLogo />
      </Stack>
    );
  }

  return (
    <Wrapper>
      <HStack alignItems='center' marginBottom='18px'>
        {!isEditingTitle && (
          <Text fontSize='24px' margin={0}>
            {selectedNoteTitle}

            <ChakraButton variant='plain' onClick={() => setIsEditingTitle(true)}>
              <svg
                viewBox='0 0 24 24'
                width='14'
                height='12'
                stroke='grey'
                strokeWidth='1'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
                style={{ height: '14px' }}
              >
                <path d='M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z'></path>
              </svg>
            </ChakraButton>
          </Text>
        )}
        {isEditingTitle && (
          <>
            <Input
              type='text'
              placeholder='Snippet Name'
              value={selectedNoteTitle}
              onChange={(e) => setSelectedNoteTitle(e.target.value)}
              spellCheck={false}
              autoComplete='off'
            />
            <Button
              size='sm'
              backgroundColor='#2c77e7'
              color='white'
              onClick={() => updateNote()}
              disabled={isSaving}
              style={{ transform: 'translate(0px)' }}
            >
              {isSaving ? <>Updating...</> : 'Update'}
            </Button>
          </>
        )}
      </HStack>

      <EditorContainer style={{ visibility: editorLoading ? 'hidden' : 'visible' }}>
        <JoditEditor
          ref={editor}
          value={cleanSnippet(selectedNoteBlurb)}
          config={config}
          onChange={(value) => setSelectedNoteBlurb(value)}
        />
      </EditorContainer>
    </Wrapper>
  );
}
