export const decodeHTML = (encodedStr: string) => {
  let decodedStr = encodedStr;

  try {
    while (decodedStr !== decodeURIComponent(decodedStr)) {
      decodedStr = decodeURIComponent(decodedStr);
    }
  } catch (e) {
    console.error('Error decoding string:', e);
  }

  return decodedStr;
};
