/** @jsxImportSource @emotion/react */
import React from 'react';
import { SiimplLogoText } from './LoadingLogo.style';
import { css, keyframes } from '@emotion/react';
import { Box, Text } from '@chakra-ui/react';

const bounceAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
`;

const dotStyle = css`
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-image: linear-gradient(135deg, #698efb, #7afec9);
  border-radius: 50%;
  animation: ${bounceAnimation} 1.5s infinite ease-in-out;
`;

const containerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-flex;

  & span:nth-of-type(1) {
    animation-delay: 0ms;
  }

  & span:nth-of-type(2) {
    animation-delay: 200ms;
  }

  & span:nth-of-type(3) {
    animation-delay: 400ms;
  }
`;

export const AnimatedEllipsis: React.FC = () => {
  return (
    <Box css={containerStyle}>
      <Text as='span' css={dotStyle}></Text>
      <Text as='span' css={dotStyle}></Text>
      <Text as='span' css={dotStyle}></Text>
    </Box>
  );
};

export default AnimatedEllipsis;

export const LoadingLogo = (): JSX.Element => {
  return (
    <SiimplLogoText>
      Siimpl
      <AnimatedEllipsis />
    </SiimplLogoText>
  );
};
