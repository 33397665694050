import { Button } from '@chakra-ui/react';

interface AuthButtonProps {
  onClick: () => Promise<void>;
  src: string;
  label: string;
}

export const AuthButton = (props: AuthButtonProps) => {
  const { onClick, src, label } = props;

  return (
    <Button
      variant='plain'
      background='white'
      display='flex'
      justifyContent='center'
      alignItems='center'
      onClick={onClick}
      width='100%'
      height='40px'
      borderRadius='8px'
      cursor='pointer'
    >
      <div
        style={{
          width: label === 'Apple' ? '20px' : '30px',
          display: 'flex',
          justifyContent: 'center',
          transform: label === 'Apple' ? 'translate(-5px, -2px)' : 'unset',
        }}
      >
        <img width={label === 'Apple' ? '20px' : '30px'} alt='Google sign-in' src={src} />
      </div>

      <span>{label}</span>
    </Button>
  );
};
