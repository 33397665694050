export const getUUIDFromURL = () => {
  // Regex to match UUIDs in the URL
  const uuidRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

  // Find the UUID in the URL
  const match = RegExp(uuidRegex).exec(location.pathname);

  // Return the UUID if found, otherwise return null
  return match ? match[0] : null;
};

export const getCollectionFromURL = () => {
  // Regex to match the part after `/b/` or `/b/collection/`
  const collectionRegex = /\/b\/(?:collection\/)?([^/?]+)/;

  const match = RegExp(collectionRegex).exec(location.pathname);

  return match ? match[1] : null;
};
