import React, { useContext, useState } from 'react';

import { SidebarNavigationItem } from './Components/SidebarNavigationItem.component';
import { ReactComponent as HomeIcon } from '../../Assets/svg/home.svg';
import { ReactComponent as BookmarkIcon } from '../../Assets/svg/bookmark.svg';
import { ReactComponent as TabsIcon } from '../../Assets/svg/tabs.svg';
import { ReactComponent as SnippetIcon } from '../../Assets/svg/snippet.svg';
import { ReactComponent as TrashIcon } from '../../Assets/svg/trash.svg';
import { ReactComponent as MoreIcon } from '../../Assets/svg/more-horizontal.svg';
import { BsStar } from 'react-icons/bs';
import { Text, VStack } from '@chakra-ui/react';
import { DataContext } from '../../providers/dataProvider';

import { Dropdown, DropdownButton } from 'react-bootstrap';
import UserDetails from '../UserDetails/UserDetails';
import Modal from '../Modal/Modal.component';
import Settings from '../Settings/Settings';
import { AddCollectionButton, CollectionHeader } from './SideNavigation.style';
import CollectionEditor from '../collectionEditor/collectionEditor.component';
import CollectionPin from '../collectionPin/collectionPin.component';
import { Link } from 'react-router-dom';
import { useGetSharedCollections } from '../../hooks/useGetSharedCollections/useGetSharedCollections.hook';

export interface UserCollectionProps {
  CollectionParentId: string;
  CollectionId: string;
  CollectionName: string;
  isEditable: number;
  isOwn: number;
  isHidden: number;
  isNested: number;
  DateShared: string;
  DateCreated: string;
  LastAdded: string;
}

interface SideNavigationProps {
  sidebarActive: boolean;
  setSidebarActive: (val: boolean) => void;
}

export const SideNavigation = (props: SideNavigationProps): JSX.Element => {
  const { sidebarActive, setSidebarActive } = props;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error
  // @ts-ignore
  const { userCollections, sharedCollections, setUserCollections } = useContext(DataContext);
  const [activeCollection, setActiveCollection] = useState('');
  const [showCollectionPin, setShowCollectionPin] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showCollectionCreator, setShowCollectionCreator] = useState(false);
  const [, setSortTime] = useState(new Date());

  const userCollectionsArray: UserCollectionProps[] = userCollections;

  const parentUserCollections = userCollectionsArray.filter((collection) => collection?.isNested === 0);

  const nestedCollections = userCollectionsArray.filter((collection) => collection.CollectionParentId !== null);

  const isParentOfNestedCollection = (id: string): boolean =>
    nestedCollections.some((collection) => collection.CollectionParentId === id);

  const sortCollections = (sort: string): void => {
    let newOrder: UserCollectionProps[] = [];
    switch (sort) {
      case 'Title A-Z':
        newOrder = userCollectionsArray.sort((a: UserCollectionProps, b: UserCollectionProps) =>
          a?.CollectionName?.localeCompare(b?.CollectionName),
        );
        break;

      case 'Title Z-A':
        newOrder = userCollectionsArray.sort((a: UserCollectionProps, b: UserCollectionProps) =>
          b?.CollectionName?.localeCompare(a?.CollectionName),
        );
        break;

      case 'Oldest':
        newOrder = userCollectionsArray.sort(
          (a, b) =>
            new Date(a.DateShared || a.DateCreated).valueOf() - new Date(b?.DateShared || b?.DateCreated).valueOf(),
        );
        break;

      case 'Latest':
        newOrder = userCollectionsArray.sort(
          (a: UserCollectionProps, b: UserCollectionProps) =>
            new Date(b?.DateShared || b?.DateCreated).valueOf() - new Date(a?.DateShared || a?.DateCreated).valueOf(),
        );
        break;

      case 'Recent':
        newOrder = userCollectionsArray.sort(
          (a: UserCollectionProps, b: UserCollectionProps) =>
            new Date(b?.LastAdded).valueOf() - new Date(a?.LastAdded).valueOf(),
        );
        break;

      default:
        newOrder = userCollectionsArray.sort(
          (a: UserCollectionProps, b: UserCollectionProps) =>
            new Date(b?.DateShared || b?.DateCreated).valueOf() - new Date(a?.DateShared || a?.DateCreated).valueOf(),
        );
    }

    // TODO: update this once DataProvider is converted to TS
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    setUserCollections(newOrder);
    setSortTime(new Date());
  };

  return (
    <div className={`siimpl-sidebar ${sidebarActive ? 'active' : ''}`}>
      <div className='sidebar-content'>
        <div className='sidebar-section'>
          <SidebarNavigationItem
            id='home'
            label='Home'
            path='/b/collection/home'
            icon={<HomeIcon style={{ width: '15px' }} />}
            setSidebarActive={setSidebarActive}
          />
          <SidebarNavigationItem
            id='Uncategorized'
            label='Uncategorized'
            path='/b/collection/Uncategorized'
            icon={<BookmarkIcon style={{ width: '15px' }} />}
            setSidebarActive={setSidebarActive}
          />
          <SidebarNavigationItem
            id='Favourites'
            label='Favourites'
            path='/b/collection/favourites'
            icon={<BsStar style={{ strokeWidth: '0.4px', height: '20px', width: '16px' }} />}
            setSidebarActive={setSidebarActive}
          />
          <SidebarNavigationItem
            id='tabs'
            label='Tab Collections'
            path='/b/tabs'
            icon={<TabsIcon style={{ width: '15px' }} />}
            setSidebarActive={setSidebarActive}
          />
          <SidebarNavigationItem
            id='text-snippets'
            label='Text Snippets'
            path='/b/text-snippets'
            icon={<SnippetIcon style={{ width: '15px' }} />}
            setSidebarActive={setSidebarActive}
          />
          <SidebarNavigationItem
            id='trash'
            label='Trash'
            path='/b/collection/Trash'
            icon={<TrashIcon style={{ width: '15px' }} />}
            setSidebarActive={setSidebarActive}
          />
        </div>
        <AddCollectionButton
          onClick={() => {
            setShowCollectionCreator(true);
            setSidebarActive(false);
          }}
        >
          Add Collection
        </AddCollectionButton>

        <VStack alignItems='flex-start' gap='0' mb='14px' className='sidebar-section'>
          {sharedCollections.length > 0 && (
            <>
              <CollectionHeader mb='4px' fontSize='16px'>
                Collections shared with you
              </CollectionHeader>
              {sharedCollections.map((collection: UserCollectionProps, index) => (
                <SidebarNavigationItem
                  key={index}
                  id={collection.CollectionId}
                  label={collection.CollectionName}
                  path={`/b/collection/${collection.CollectionId}`}
                  setActiveCollection={() =>
                    setActiveCollection((prevState) =>
                      prevState === collection.CollectionId ? '' : collection.CollectionId,
                    )
                  }
                  setSidebarActive={setSidebarActive}
                />
              ))}
            </>
          )}
        </VStack>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CollectionHeader mb='4px' fontSize='16px'>
            Collections
          </CollectionHeader>
          <DropdownButton
            className='collection-options'
            variant='link'
            style={{ color: 'inherit' }}
            title={<MoreIcon />}
            menuAlign='right'
          >
            <Dropdown.Item onClick={() => sortCollections('Latest')}>Sort Latest</Dropdown.Item>
            <Dropdown.Item onClick={() => sortCollections('Oldest')}>Sort Oldest</Dropdown.Item>
            <Dropdown.Item onClick={() => sortCollections('Title A-Z')}>Sort Title A-Z</Dropdown.Item>
            <Dropdown.Item onClick={() => sortCollections('Title Z-A')}>Sort Title Z-A</Dropdown.Item>
            <Dropdown.Item onClick={() => sortCollections('Recent')}>Sort Recently Added</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => setShowCollectionPin(true)}>Show hidden collections</Dropdown.Item>
          </DropdownButton>
        </div>
        <div className='sidebar-section sidebar-section-bottom w-100'>
          {parentUserCollections.map((collection: UserCollectionProps) => (
            <div key={collection.CollectionId}>
              <SidebarNavigationItem
                id={collection.CollectionId}
                label={collection.CollectionName}
                path={`/b/collection/${collection.CollectionId}`}
                isLocked={collection.isOwn === 0 && collection.isEditable === 0}
                isUnlocked={collection.isOwn === 0 && collection.isEditable === 1}
                isHidden={collection.isHidden === 1}
                isParent={isParentOfNestedCollection(collection.CollectionId)}
                setActiveCollection={() =>
                  setActiveCollection((prevState) =>
                    prevState === collection.CollectionId ? '' : collection.CollectionId,
                  )
                }
                isActiveParentCollection={activeCollection === collection.CollectionParentId}
                setSidebarActive={setSidebarActive}
              />

              {nestedCollections
                .filter((nestedCollection) => nestedCollection.CollectionParentId === collection.CollectionId)
                .map((nestedItem, index) => (
                  <div
                    key={index}
                    className={
                      activeCollection === nestedItem.CollectionParentId
                        ? 'nested-collection show pl-3'
                        : 'nested-collection pl-3'
                    }
                  >
                    <SidebarNavigationItem
                      id={nestedItem.CollectionId}
                      label={nestedItem.CollectionName}
                      path={`/b/collection/${nestedItem.CollectionId}`}
                      isLocked={nestedItem.isOwn === 0 && nestedItem.isEditable === 0}
                      isUnlocked={nestedItem.isOwn === 0 && nestedItem.isEditable === 1}
                      isHidden={nestedItem.isHidden === 1}
                      setSidebarActive={setSidebarActive}
                    />
                  </div>
                ))}
            </div>
          ))}
        </div>
        <div style={{ maxHeight: 70, marginTop: 'auto', paddingTop: 10 }}>
          <UserDetails showSettings={() => setShowSettings(true)} />
        </div>
      </div>

      <Modal
        show={showCollectionPin}
        onClose={() => {
          setShowCollectionPin(false);
        }}
      >
        <CollectionPin
          close={() => setShowCollectionPin(false)}
          openSettings={() => {
            setShowCollectionPin(false);
            setShowSettings(true);
          }}
        />
      </Modal>

      <Modal
        show={showSettings}
        onClose={() => {
          setShowSettings(false);
        }}
        maxWidth={500}
      >
        <Settings />
      </Modal>

      <Modal
        show={showCollectionCreator}
        onClose={() => {
          setShowCollectionCreator(false);
        }}
      >
        <CollectionEditor isAdd={true} close={() => setShowCollectionCreator(false)} />
      </Modal>
    </div>
  );
};
