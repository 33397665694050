import { Button } from '../../styles/button.style';
import styled from '@emotion/styled';
import { Text } from '@chakra-ui/react';

export const AddCollectionButton = styled(Button)`
  background-color: #2c77e7;
  color: white;
  margin-bottom: 16px;
  height: 30px;
  width: 100%;
  font-size: 13px;

  &:hover {
    border-color: #698efb;
  }
`;

export const CollectionHeader = styled(Text)`
  background: linear-gradient(135deg, #698efb, #7afec9);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  color: white;
`;
