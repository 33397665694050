import { Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const SiimplLogoText = styled(Text)`
  background-image: linear-gradient(135deg, #698efb, #7afec9);
  color: white;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 100px;
  font-weight: 200;
`;
