/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, createContext, useMemo, PropsWithChildren } from 'react';
import { Bookmark } from '../defintions/Bookmark.defintion';

//TODO: Correctly type this legacy code

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const DataContext = createContext({
  userCollections: [] as any[],
  setUserCollections: (value: any) => [],
  userBookmarks: [],
  setUserBookmarks: (value: any) => [],
  collectionBookmarks: [],
  setCollectionBookmarks: (value: any) => [],
  sharedCollections: [],
  setSharedCollections: (value: any) => [],
});

export default function DataProvider(props: PropsWithChildren): JSX.Element {
  const [userCollections, setUserCollections] = useState<any[]>([]);
  const [userBookmarks, setUserBookmarks] = useState<Bookmark[]>([]);
  const [collectionBookmarks, setCollectionBookmarks] = useState<Bookmark[]>([]);
  const [sharedCollections, setSharedCollections] = useState<any[]>([]);

  const providerValue = useMemo(
    () => ({
      userCollections,
      setUserCollections,
      userBookmarks,
      setUserBookmarks,
      collectionBookmarks,
      setCollectionBookmarks,
      sharedCollections,
      setSharedCollections,
    }),
    [
      userCollections,
      setUserCollections,
      userBookmarks,
      setUserBookmarks,
      collectionBookmarks,
      setCollectionBookmarks,
      sharedCollections,
      setSharedCollections,
    ],
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  return <DataContext.Provider value={providerValue}>{props.children}</DataContext.Provider>;
}
