import { useEffect, useState } from 'react';
import { fetchAPI } from '../../util/data/fetchAPI.util';

export const useGetSharedCollections = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getSharedCollections = async () => {
    fetchAPI
      .get('/apiv2/getSharedCollections')
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        setError(err);
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getSharedCollections();
  }, []);

  return {
    data,
    loading,
    error,
  };
};
