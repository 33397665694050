import styled from '@emotion/styled';

export const ModalWrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 10000;
  inset: 0;
`;

export const Overlay = styled.div`
  position: fixed;
  inset: 0;
  background: #2d2c5f96;
  z-index: 1000;
`;

export const ModalContainer = styled.div`
  background: white;
  z-index: 10000000;
  margin: auto;
  padding-right: 0px;
  border-radius: 10px;
  width: 95vw;
  max-height: 95vh;
  overflow: hidden;
`;
