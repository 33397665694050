import { DownloadGroup } from '../../../website/pages/homePage/homePage.style';
import { AppButton } from '../../../website/components/ui/appButton/appButton';
import { Link, Text, VStack } from '@chakra-ui/react';

export default function NoBookmarks() {
  return (
    <VStack justifyContent='center' alignItems='center' minHeight={'70vh'}>
      <Text fontSize='42px' opacity='0.8' textAlign='center' marginTop='50px'>
        It looks a little empty in here!
      </Text>
      <Text fontSize='18px' opacity='0.8' textAlign='center' width='60%'>
        The best way to add bookmarks is through our app or chrome extension, you can also add them by clicking on "Add
        Bookmark"
      </Text>
      <DownloadGroup marginTop='30px' gap='32px'>
        <AppButton
          src='https://res.cloudinary.com/siimpl/image/upload/v1737657931/Apple_logo_black.svg_b4ejrx.png'
          header='Download on the'
          label='App Store'
          link='https://apps.apple.com/ca/app/siimpl-bookmarking/id1533377621'
        />
        <Link
          href='https://chrome.google.com/webstore/detail/siimpl-bookmarking/fjdfngkdfcmcibmbfinnbbeabbdjcghc'
          target='_blank'
          rel='noreferrer'
        >
          <img
            src='https://res.cloudinary.com/siimpl/image/upload/v1737833864/Google_Chrome_icon__September_2014_.svg_p5q7z8.png'
            width='50px'
            height='50px'
          />
        </Link>
        <Link
          href='https://microsoftedge.microsoft.com/addons/detail/siimpl-bookmarking/gghgokkhgpbinokcfbojaeikcnjicbha'
          target='_blank'
          rel='noreferrer'
        >
          <img
            src='https://res.cloudinary.com/siimpl/image/upload/v1737833942/Microsoft_Edge_logo__2019_ketxfy.png'
            width='50px'
            height='50px'
          />
        </Link>
        {/* <AppButton
                    header='Get it on'
                    src='https://res.cloudinary.com/siimpl/image/upload/v1737657583/99256cf85aebeff57fa10e5f773d3bd4_tnre79.png'
                    label='Google Play'
                    link='https://play.google.com/store/apps/details?id=com.siimpl'
                  /> */}
      </DownloadGroup>
      {/* <div className='m-auto text-center' style={{ color: '#cccccc', fontWeight: 200 }}>
        <p className='m-auto' style={{ fontSize: 100, color: '#e6e6e6' }}>
          Siimpl
        </p>
        <p className='mb-0' style={{ fontSize: 30 }}>
          Bookmarks from our app and extensions will show here.
        </p>
        <ul className='platforms-list list-inline my-1'>
          <li className='list-inline-item'>
            <a
              href='https://chrome.google.com/webstore/detail/siimpl-bookmarking/fjdfngkdfcmcibmbfinnbbeabbdjcghc'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src='https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Chrome_icon_%28September_2014%29.svg/240px-Google_Chrome_icon_%28September_2014%29.svg.png'
                alt=''
                className='platform-image img-fluid'
              />
            </a>
          </li>
          <li className='list-inline-item'>
            <a
              href='https://microsoftedge.microsoft.com/addons/detail/siimpl-bookmarking/gghgokkhgpbinokcfbojaeikcnjicbha'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src='https://cdn.vox-cdn.com/thumbor/VeEPEECslTmxDJwzV2RHXhslYpg=/0x0:1980x1320/920x613/filters:focal(832x502:1148x818):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/65613211/microsoftedgenewlogo.5.jpg'
                alt=''
                className='platform-image img-fluid'
              />
            </a>
          </li>
          <li className='list-inline-item'>
            <a
              href='https://addons.mozilla.org/en-CA/firefox/addon/siimpl-bookmarking/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src='https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Firefox_Project_Logo%2C_2019.svg/1024px-Firefox_Project_Logo%2C_2019.svg.png'
                alt=''
                className='platform-image img-fluid'
              />
            </a>
          </li>
        </ul>
        <a
          href='https://apps.apple.com/ca/app/siimpl-bookmarking/id1533377621'
          target='_blank'
          rel='noreferrer'
          className='mobile-app'
        >
          <img src={AppleStoreLogo} alt='' />
        </a>
        <a
          href='https://play.google.com/store/apps/details?id=com.siimpl'
          target='_blank'
          rel='noreferrer'
          className='mobile-app'
        >
          <img src={GooglePlayLogo} alt='' className='ml-2' />
        </a>
        <p className='mb-0' style={{ fontSize: 30 }}>
          To add your own from this web app please create a collection
        </p>
      </div> */}
    </VStack>
  );
}
