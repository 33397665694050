import { AuthProps } from '../..';
import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { Input, LoginButton } from '../../auth.style';
import { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

export const Forgotten = (props: AuthProps) => {
  const { onAuthChange } = props;

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleForgotPassword = () => {
    if (email === '') {
      setError('Enter email above and hit Forgot Password');
    } else {
      setError(`Reset email has been sent to ${email}`);
    }
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setError(`Reset email has been sent to ${email}`);
      })
      .catch((error) => {
        const errorMessage = error.message;
        setError(errorMessage);
      });
  };

  return (
    <>
      <Text fontSize='26px' color='white'>
        Forgotten password
      </Text>
      <Text fontSize='14px' fontWeight='400' color='white'>
        Enter the email address associated with your Siimol account, and we’ll send you a reset email.
      </Text>
      <Input
        placeholder='Email'
        background='white'
        type='email'
        name='email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {error && <div style={{ color: '#b73c3c', fontSize: '12px', marginRight: 'auto' }}>{error}</div>}

      <LoginButton variant='plain' onClick={handleForgotPassword}>
        Reset password
      </LoginButton>

      <Box height='1px' background='#6c6c6c' width='100%' />

      <Text color='white'>
        Back to{' '}
        <Button variant='plain' cursor='pointer' onClick={() => onAuthChange('login')}>
          <Text as='h5'>Login</Text>
        </Button>
      </Text>
    </>
  );
};
