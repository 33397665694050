import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { Input, LoginButton } from '../../auth.style';
import { signInWithApple, signInWithEmail, signInWithGoogle } from '../../../../util/auth/firebase';
import { AuthButton } from '../../components/authButton/authButton';
import { useEffect, useRef, useState } from 'react';
import { AuthProps } from '../..';

export const Login = (props: AuthProps) => {
  const { onAuthChange, onAuth } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string>('');
  const emailField = useRef(null);
  const passwordField = useRef(null);

  const handleGoogleAuth = async () => {
    await signInWithGoogle().then(() => onAuth?.());
  };

  const handleAppleAuth = async () => {
    await signInWithApple().then(() => onAuth?.());
  };

  const handleEmailAuth = () => {
    signInWithEmail({ email, password })
      .then(() => onAuth?.())
      .catch(() => setError('Error signing in with password and email!'));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (emailField.current) {
        setEmail((emailField.current as HTMLInputElement)?.value);
        clearInterval(interval);
      }
    }, 100);

    const intervalP = setInterval(() => {
      if (passwordField.current) {
        setPassword((passwordField.current as HTMLInputElement)?.value);
        clearInterval(intervalP);
      }
    }, 100);
  }, []);

  return (
    <>
      <Text fontSize='26px' color='white'>
        Login
      </Text>
      <AuthButton
        onClick={handleGoogleAuth}
        src='https://res.cloudinary.com/siimpl/image/upload/v1737657583/99256cf85aebeff57fa10e5f773d3bd4_tnre79.png'
        label='Google'
      />
      <AuthButton
        onClick={handleAppleAuth}
        src='https://res.cloudinary.com/siimpl/image/upload/v1737657931/Apple_logo_black.svg_b4ejrx.png'
        label='Apple'
      />

      <HStack justifyContent='center' alignItems='center' color='white'>
        <Box height='1px' background='#6c6c6c' width='110px' />
        <Text>or</Text>
        <Box height='1px' background='#6c6c6c' width='110px' />
      </HStack>
      <form autoComplete='on'>
        <Input
          ref={emailField}
          placeholder='Email'
          background='white'
          type='email'
          name='email'
          id='email'
          autoComplete='email'
          inputMode='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          mb='8px'
        />
        <Input
          ref={passwordField}
          placeholder='Password'
          background='white'
          type='password'
          name='password'
          id='password'
          autoComplete='current-password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </form>
      {error && <div style={{ color: '#b73c3c', fontSize: '12px', marginRight: 'auto' }}>{error}</div>}
      <Button
        variant='plain'
        color='white'
        fontSize='12px'
        marginRight='auto'
        cursor='pointer'
        onClick={() => onAuthChange('forgotten')}
      >
        Forgotten password?
      </Button>
      <LoginButton variant='plain' onClick={handleEmailAuth}>
        Log in
      </LoginButton>

      <Box height='1px' background='#6c6c6c' width='100%' />

      <Text color='white'>
        Not a member?{' '}
        <Button variant='plain' cursor='pointer' onClick={() => onAuthChange('register')}>
          <Text as='h5'>Sign up</Text>
        </Button>
      </Text>
    </>
  );
};
