/* eslint-disable @typescript-eslint/no-explicit-any */

import { UserInfo } from 'firebase-admin/lib/auth/user-record';
import React, { useState, createContext, useEffect, useMemo, Dispatch, SetStateAction } from 'react';
import { auth, getUserDocument } from '../util/auth/firebase';
import posthog from 'posthog-js';

interface UserContextProps {
  userLoading: boolean;
  user: any;
  userDetails: any;
  userTheme: any;
  setUserTheme: Dispatch<SetStateAction<string>>;
}

export const UserContext = createContext<Partial<UserContextProps>>({});

interface SiimplUser extends Omit<UserInfo, 'phoneNumber' | 'email' | 'providerId'> {
  collectionSort: string;
  isFirstLogin: boolean;
  userBio: string;
  siimplSauce: string;
  displayType: string;
  theme?: string;
}

export default function UserProvider(props: any): JSX.Element {
  const [user, setUser] = useState<any>(null);
  const [userLoading, setUserLoading] = useState(true);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [userTheme, setUserTheme] = useState('');

  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      console.log('UserProvider: onAuthStateChanged', userAuth);
      //const credential = GoogleAuthProvider.credential(null, userAuth?.getIdToken());
      getUserDetails(userAuth).catch(console.log);

      setUserLoading(false);
      setUser(userAuth);
      posthog.init('phc_A2TE448BE6GZjehFE7pZRyXFWj8LTQUSa1KqLsC7Hwe', {
        api_host: 'https://us.i.posthog.com',
        loaded: () => {
          posthog?.identify?.(userAuth?.email ?? userAuth?.uid, {
            id: userAuth?.uid,
          });
        },
      });
    });
  }, []);

  const getUserDetails = async (userAuth: any): Promise<void> => {
    if (userAuth != null) {
      const { collectionSort, displayName, isFirstLogin, photoURL, uid, userBio, siimplSauce, displayType, theme } =
        (await getUserDocument(userAuth.uid)) as SiimplUser;

      const siimplUser = {
        collectionSort,
        displayName,
        isFirstLogin,
        photoURL,
        uid,
        userBio,
        siimplSauce,
        displayType,
        email: userAuth.email,
      };

      setUserDetails(siimplUser);
      setUserTheme(theme ?? '');
      localStorage.setItem('user', JSON.stringify(siimplUser));
    }
  };

  const providerValue: UserContextProps = useMemo(
    () => ({
      userLoading,
      user,
      userDetails,
      setUserDetails,
      userTheme,
      setUserTheme,
    }),
    [user, userLoading, userDetails, setUserDetails, userTheme, setUserTheme],
  );

  return <UserContext.Provider value={providerValue}>{props.children}</UserContext.Provider>;
}
