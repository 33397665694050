import React, { useState } from 'react';

import { Login } from './pages/login/login';
import { Register } from './pages/register/register';
import { Forgotten } from './pages/forgotten/forgotten';

import { Wrapper, AuthContainer, Container } from './auth.style';
import { Logo } from '../website/pages/homePage/homePage.style';
import { Box, Button } from '@chakra-ui/react';

export interface AuthProps {
  onAuthChange: (type: string) => void;
}

const AuthenticatedScreen = () => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        background: 'black',
        color: 'white',
      }}
    >
      <p style={{ margin: 0 }}>You are now authenticated</p>
      <p>Click Siimpl extension icon to get started</p>

      <Button
        variant='plain'
        onClick={() => window.close()}
        backgroundColor='blue'
        color='white'
        width='140px'
        height='40px'
        borderRadius='8px'
        marginTop='20px'
        cursor='pointer'
      >
        Close
      </Button>
    </div>
  );
};

function ExtensionAuth() {
  const [authType, setAuthType] = useState('login');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleAuthenticated = () => {
    setIsAuthenticated(true);
  };

  const handleChangeType = (type: string) => {
    setAuthType(type);
  };

  let component;

  switch (authType) {
    case 'login':
      component = <Login onAuthChange={handleChangeType} onAuth={handleAuthenticated} />;
      break;

    case 'register':
      component = <Register onAuthChange={handleChangeType} onAuth={handleAuthenticated} />;
      break;

    case 'forgotten':
      component = <Forgotten onAuthChange={handleChangeType} onAuth={handleAuthenticated} />;
      break;

    default:
      component = <Login onAuthChange={handleChangeType} onAuth={handleAuthenticated} />;
  }

  if (isAuthenticated) {
    return <AuthenticatedScreen />;
  }

  return (
    <Wrapper>
      <Container>
        <Box textAlign='center' marginBottom='40px'>
          <Logo to='/'>
            Siimpl<span style={{ color: '#43a0d1' }}>.</span>
          </Logo>
        </Box>

        <AuthContainer>{component}</AuthContainer>
      </Container>
    </Wrapper>
  );
}

export default ExtensionAuth;
