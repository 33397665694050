import { HStack } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding: 16px;

  @media (max-width: 800px) {
    padding: 16px 4px;
  }
`;

export const Header = styled(HStack)`
  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;

    .sort-container {
      margin-left: unset;
    }
  }
`;
