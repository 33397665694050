import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LockIcon } from '../../../Assets/svg/lock.svg';
import { ReactComponent as UnlockIcon } from '../../../Assets/svg/unlock.svg';
import { ReactComponent as HiddenIcon } from '../../../Assets/svg/hidden.svg';
import { ReactComponent as ChevronIcon } from '../../../Assets/svg/chevron.svg';
import { getCollectionFromURL } from '../../../util/navigation/navigation.util';

export const SidebarNavigationItem = (props: SidebarNavigationItemType): JSX.Element => {
  const {
    id,
    icon,
    label,
    path,
    isLocked,
    isUnlocked,
    isHidden,
    isParent,
    isActiveParentCollection,
    setActiveCollection,
    setSidebarActive,
  } = props;

  const currentCollection = getCollectionFromURL();

  return (
    <div className='sidebar-item' id={`${id}-${label}`}>
      <Link
        to={path}
        onClick={() => {
          setSidebarActive?.(false);
        }}
      >
        <div style={{ display: 'flex', flex: 1 }}>
          {icon}
          <span>
            {label}
            <div
              className={`sidebar-item-indicator ${currentCollection === id && 'sidebar-item-indicator_active'} `}
            ></div>
          </span>
        </div>
        <div>
          {isLocked && <LockIcon />}
          {isUnlocked && <UnlockIcon />}
          {isHidden && <HiddenIcon />}
        </div>
      </Link>

      {isParent && (
        <ChevronIcon
          className={isActiveParentCollection ? 'nested-chevron.rotate' : ''}
          onClick={setActiveCollection}
        />
      )}
    </div>
  );
};

interface SidebarNavigationItemType {
  id?: string;
  icon?: ReactNode;
  label: string;
  path: string;
  isLocked?: boolean;
  isUnlocked?: boolean;
  isHidden?: boolean;
  isParent?: boolean;
  isActiveParentCollection?: boolean;
  setActiveCollection?: () => void;
  setSidebarActive?: (val: boolean) => void;
}
