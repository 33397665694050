import { Box, Stack, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { fetchAPI } from '../../util/data/fetchAPI.util';
import { useEffect, useState } from 'react';
import Masonry from 'react-masonry-css';
import { BookmarkType } from '../../types/BookmarkType';
import '../../Components/BookmarkCard/BookmarkCard.scss';
import { HeroTitle, Logo } from '../website/pages/homePage/homePage.style';
import { CenterWrapper, Header, Wrapper } from './public.style';

const breakpointColumnsObj = {
  default: 5,
  1800: 5,
  1600: 4,
  700: 2,
  100: 1,
};

export const Public = () => {
  const { id } = useParams<any>();

  const [bookmarks, setBookmarks] = useState<SharedCollectionBookmarks[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getBookmarks = (): void => {
    fetchAPI
      .get(`/api/getCollectionPublic`, {
        headers: {
          collectionid: id,
        },
      })
      .then((data) => {
        const sortedData = data.sort(
          (a: any, b: any) => new Date(b.DateCreated).getTime() - new Date(a.DateCreated).getTime(),
        );
        setBookmarks(sortedData);
        setIsLoading(false);
      })
      .catch(console.error);
  };

  useEffect(() => {
    getBookmarks();
  }, []);

  const openBookmark = (bookmark: BookmarkType): void => {
    window.open(bookmark.BookmarkURL, '_blank');
    //   bookmark.BookmarkType === 'image' ? openImageLightbox(bookmark.Id) : window.open(bookmark.BookmarkURL, '_blank');
  };

  const decode = (blurb: string): string => {
    if (blurb?.includes('%20')) {
      return decodeURIComponent(blurb);
    }
    return blurb;
  };

  const getHostName = (bookmarkUrl: BookmarkType['BookmarkURL']): string => {
    if (!bookmarkUrl) {
      return bookmarkUrl;
    }

    try {
      const url = new URL(bookmarkUrl);
      return url?.hostname;
    } catch {
      return bookmarkUrl;
    }
  };

  if (!isLoading && bookmarks.length === 0) {
    return (
      <CenterWrapper>
        <Box position='absolute' top='10px' left='0' right='0'>
          <Logo to='/'>
            Siimpl<span style={{ color: '#43a0d1' }}>.</span>
          </Logo>
        </Box>

        <HeroTitle>Hmm..</HeroTitle>
        <Text fontSize='32px'>There's nothing here 😕</Text>
        <Text fontSize='18px'>
          It looks like the page you’re trying to access doesn’t exist or may have moved. If you know the owner you can
          contact them to see if it’s available.
        </Text>
        <Text fontSize='18px'>
          Still need help? Feel free to contact us at <a href='mailto:hello@siimpl.co'>hello@siimpl.co</a>
        </Text>
      </CenterWrapper>
    );
  }

  return (
    <Wrapper>
      <Stack alignItems='center' marginBottom='20px'>
        <Logo to='/'>
          Siimpl<span style={{ color: '#43a0d1' }}>.</span>
        </Logo>
      </Stack>

      <Header alignItems='flex-start'>
        <Box>
          <Text color='white' fontSize='1.5rem' fontWeight={600} marginBottom={0} lineHeight='1rem'>
            {bookmarks?.[0]?.CollectionName}
          </Text>
          <Text color='white' fontSize='1rem' fontWeight={400}>
            {bookmarks?.[0]?.ownerName}
          </Text>
        </Box>
      </Header>

      {bookmarks?.[0]?.CollectionDesc && (
        <Text color='white' fontSize='1rem' fontWeight={400} marginBottom='40px' width='80%'>
          {bookmarks?.[0]?.CollectionDesc}
        </Text>
      )}

      <Masonry
        className='my-masonry-grid bookmark-grid'
        breakpointCols={breakpointColumnsObj}
        columnClassName='my-masonry-grid_column'
      >
        {bookmarks.map((item: any, index) => (
          <div className='preview' key={index}>
            <div
              className={`preview-inner`}
              style={{ background: '#15191f', outline: 'solid 1px rgba(31, 41, 55, 0.9490196078)' }}
            >
              <div className='img-container' onClick={() => openBookmark(item)}>
                <img src={item.BookmarkImage} />
              </div>

              <a href={item.BookmarkURL} rel='noreferrer' target='_blank' style={{ color: 'white' }}>
                <p className='card-title'>{item.BookmarkName}</p>
              </a>

              <div className='link'>
                <a className='card-url' href={item.BookmarkURL} rel='noreferrer' target='_blank'>
                  {getHostName(item.BookmarkURL)}
                </a>
              </div>
              <p className='card-wording' style={{ color: 'white' }}>
                {decode(item.BookmarkBlurb)}
              </p>
            </div>
          </div>
        ))}
      </Masonry>
    </Wrapper>
  );
};

type SharedCollectionBookmarks = BookmarkType & {
  ownerName?: string;
  CollectionDesc?: string;
};
