import { getCollectionFromURL } from '../navigation/navigation.util';

export const getCollectionName = (collectionName: string) => {
  const name = getCollectionFromURL();

  switch (name) {
    case 'home':
      return 'Home';

    case 'Uncategorized':
      return 'Uncategorized';

    case 'favourites':
      return 'Favourites';

    case 'Trash':
      return 'Trash';

    default:
      return collectionName;
  }
};

export const removeDateTime = (str: string) => {
  return str.replace(/[\d/,: ]+(AM|PM)?$/, '').trim();
};

export const copyToClipboard = (value: string) => {
  navigator.clipboard.writeText(value);
};
