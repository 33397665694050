import { Button as ChakraButton } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const Button = styled(ChakraButton)`
  padding: 8px 12px;
  border-radius: 8px;
  color: #ebebeb;
  font-weight: 600;
  font-size: 15px;
  transform: translate(0px, -5px);
  outline: none !important;
`;

export const ActiveBorder = styled.div<{ active: boolean }>`
  height: 3px;
  background-image: ${(props) => (props.active ? 'linear-gradient(135deg, #698efb, #7afec9)' : 'transparent')};
  transform: translate(0px, -3px);
  border-radius: 8px;
  position: absolute;
  bottom: -3px;
  left: 15px;
  right: 15px;
`;
