export const config = {
  defaultMode: 1,
  readonly: false,
  toolbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  disablePlugins:
    'about,add-new-line,backspace,class-span,clean-html,clipboard,copyformat,drag-and-drop,drag-and-drop-element,dtd,enter,error-messages,file,focus,format-block,fullsize,hotkeys,xpath,wrap-nodes,video,table,table-keyboard-navigation,tab,symbols,sticky,stat,spellcheck,speech-recognize,select-cells,select,search,resizer,resize-handler,resize-cells,hr,iframe,image,image-processor,image-properties,justify,key-arrow-outside,limit,media,mobile,paste,paste-from-word,paste-storage,placeholder,powered-by-jodit,preview,ordered-list,print',
  width: 500,
  inline: true,
  toolbarInlineForSelection: true,
  showPlaceholder: false,
  spellChecker: false,
  sourceEditorNativeOptions: {
    theme: 'ace/theme/eclipse',
    showGutter: true,
  },
};
