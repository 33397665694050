import React, { useEffect, useState, useContext } from 'react';
import { auth } from '../../util/auth/firebase';
import { UserContext } from '../../providers/UserProvider.provider';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as MoreIcon } from '../../Assets/svg/more-horizontal.svg';


export default function UserDetails(props: UserDetailsProps): JSX.Element {
  const { showSettings } = props;
  const [userEmail, setUserEmail] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [photoURL, setPhotoURL] = useState('');

  const { userDetails } = useContext(UserContext);

  useEffect(() => {
    const user = userDetails;

    setUserEmail(user?.email);
    setPhotoURL(user?.photoURL);
    setDisplayName(user?.displayName);
  }, [userDetails]);

  const signOut = (): void => {
    void auth.signOut();
    window.location.replace('/');
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <div
          className='user-circle d-flex'
          style={
            photoURL
              ? {
                  backgroundImage: `url(${photoURL})`,
                  backgroundSize: 'cover',
                  width: '45px',
                  height: '45px',
                  borderRadius: '50%',
                }
              : {}
          }
        >
          {!photoURL && displayName && <h4 className='m-auto text-uppercase'>{displayName?.slice(0, 1) || 'X'}</h4>}
        </div>
        <div className='user-details'>
          <div>
            <strong>{displayName}</strong>
          </div>
          <div>{userEmail}</div>
        </div>
      </div>
      <Dropdown alignRight style={{ margin: 'auto 0' }}>
        <Dropdown.Toggle variant='link' className='text-muted  mr-3 ml-1'>
          <MoreIcon />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item className='mt-1' onClick={() => showSettings()}>
            Settings
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item eventKey='1' onClick={signOut} className='text-center text-danger'>
            <small>Log Out</small>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

interface UserDetailsProps {
  showSettings: () => void;
}
