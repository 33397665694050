import styled from '@emotion/styled';

export const BookmarkMoverHead = styled.div`
  position: sticky;
  top: 0px;
  z-index: 10;
  padding-bottom: 4px;
  padding-top: 12px;
  background-color: #2d3238;
`;

export const InputContainer = styled.div`
  border: solid 1px #dedede;
  border-radius: 10px;
  min-height: 24px;
  padding: 5px 0;
  width: 100%;
  min-width: 300px;
  margin-bottom: 10px;

  label {
    width: 100%;
    margin: 0;
    color: #898b8d;
    font-weight: 300;
  }

  input {
    width: 100%;
    border: none !important;
    background: transparent;
    outline: none !important;
    padding: 0 10px;
    font-size: 16px;
    color: #5a5a5a;
    font-weight: 300;
  }
`;

export const MoverContainer = styled.div`
  min-height: 70vh;
`;

export const CollectionItem = styled.div`
  padding: 5px;
  margin-bottom: 8px;
  color: #777;
  cursor: pointer;

  border-radius: 5px;

  &:hover {
    p {
      &::after {
        width: 30%;
      }
    }
  }

  p {
    margin: 7.5px 0;
    display: flex;
    align-self: flex-start;
    flex-direction: column;

    &::after {
      content: '';
      width: 0%;
      height: 2px;
      background: linear-gradient(to right, #698efb, #7afec9);
      transition: width 0.5s ease;

      &_active {
        width: 80%;
      }
    }
  }
`;
