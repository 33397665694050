import { Button, HStack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: black;

  button {
    padding: 0px 12px;
    min-height: 40px;
    cursor: pointer;
  }
`;

export const Container = styled.div`
  max-width: 1280px;
  margin: auto;

  @media screen and (max-width: 1348px) {
    padding: 0 4px;
  }
`;

/** Header Style start */

export const Header = styled.div`
  position: absolute;
  position: sticky;
  top: 0px;
  z-index: 10;
`;

export const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 78px;
  padding: 12px;
  background-color: #0e121752;
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  border-radius: 24px;
`;

export const Logo = styled(Link)`
  color: white !important;
  font-size: 40px;
  font-weight: 700;
  text-decoration: none !important;
`;

export const LoginButton = styled(Button)`
  color: white;
  border-radius: 12px;
  border: solid 2px white;
  height: 50px;
`;

/** Header Style end */

export const HeroStack = styled.div`
  grid-column-gap: 40px;
  grid-row-gap: 120px;
  flex-direction: column;
  align-items: center;
  padding-top: 96px;
  display: flex;
  position: relative;
  grid-row-gap: 96px;
  padding-left: 80px;
  padding-right: 80px;
  min-height: 90vh;

  .hero-image {
    width: 1120px;
    max-width: 90vw;
    border-radius: 18px 18px 0 0;
    opacity: 0.6;
  }
`;

export const HeroWrapper = styled.div`
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 800px;
  display: flex;
  position: relative;
  color: white;
  font-size: 30px;
  line-height: 32px;
`;

export const HeroHeading = styled.div`
  margin-top: 0;
  margin-bottom: 16px;
  font-family: Roboto, sans-serif;
  font-size: 62px;
  line-height: 85px;
  font-weight: 400;
  display: block;

  @media screen and (max-width: 680px) {
    font-size: 42px;
  }
`;

export const HeroTitle = styled.div`
  background-image: linear-gradient(135deg, #698efb, #7afec9);
  color: #698efb;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: -24px;
  font-family: Roboto, sans-serif;
  font-size: 122px;
  font-weight: 700;
  line-height: 146px;
  display: inline-block;

  @media screen and (max-width: 680px) {
    font-size: 72px;
  }
`;

export const HeroBg = styled.div`
  z-index: 0;
  border-radius: 100vw;
  background-color: #0081eceb;
  opacity: 0.26;
  -webkit-filter: blur(120px);
  filter: blur(120px);
  width: 70%;
  height: 100%;
  position: absolute;
  transform: translateY(220px);
`;

export const DownloadGroup = styled(HStack)`
  @media screen and (max-width: 680px) {
    flex-direction: column;
  }
`;

export const Divider = styled.div<{ height?: number }>`
  background-image: linear-gradient(135deg, #698efb, #7afec9);
  height: ${(props) => `${props.height ?? 10}px`};
`;

export const DetailStack = styled.div`
  min-height: 400px;
  background: #000000;
  z-index: 1;
  color: white;
  padding: 180px 0;

  h5 {
    background-image: linear-gradient(135deg, #698efb, #7afec9);
    color: #698efb;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
`;

export const Details = styled(HStack)`
  @media screen and (max-width: 1233px) {
    gap: 50px;
  }

  @media screen and (max-width: 680px) {
    flex-direction: column;

    .features,
    .blurb {
      width: 90%;
      justify-content: center;
    }
  }
`;

export const CTAStack = styled.div`
  margin-bottom: 110px;
`;

export const CTA = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 420px;
  background: linear-gradient(135deg, #7269fb8a, #7afeee59);
  border-radius: 32px;
  overflow: hidden;
  opacity: 0.9;
  position: relative;
`;

export const CTAImage = styled.div`
  width: 30%;

  height: 100%;
  background-image: url('https://res.cloudinary.com/siimpl/image/upload/v1737842762/Robot-removebg-preview_xkg2mt.png');
  background-size: cover;
  transform: scaleX(-1);
  opacity: 0.7;
  position: absolute;
  left: 0;

  @media screen and (max-width: 900px) {
  }
`;

export const CTAText = styled.div`
  width: 70%;
  z-index: 10;

  h5 {
    color: white;
    font-size: 40px;

    @media screen and (max-width: 900px) {
      font-size: 20px;
      margin-right: 20px;
    }
  }
`;

export const FooterBG = styled.div`
  border-radius: 90vw;
  background-color: #0081eceb;
  opacity: 0.26;
  filter: blur(120px);
  width: 100vw;
  height: 80%;
  margin-top: 31px;
  position: absolute;
  z-index: -1;
`;

export const Footer = styled.div`
  min-height: 300px;
  overflow: hidden;
  position: relative;
  padding-top: 80px;
  color: white;
  z-index: 10;

  a {
    color: white;
  }

  h5 {
    background-image: linear-gradient(135deg, #698efb, #7afec9);
    color: #698efb;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
`;
