import React, { useEffect, useState } from 'react';

import { Login } from './pages/login/login';
import { Register } from './pages/register/register';
import { Forgotten } from './pages/forgotten/forgotten';

import { Wrapper, AuthContainer, Container } from './auth.style';
import { Logo } from '../website/pages/homePage/homePage.style';
import { Box } from '@chakra-ui/react';
import { auth } from '../../util/auth/firebase';
import { useHistory } from 'react-router-dom';

import 'react-tooltip/dist/react-tooltip.css';

export interface AuthProps {
  onAuthChange: (type: string) => void;
  onAuth?: () => void;
}

function Auth() {
  const history = useHistory();

  const [authType, setAuthType] = useState('login');

  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        if (window.location.pathname.toLowerCase() === '/app/login') {
          const redirect = localStorage.getItem('redirect');
          if (redirect) {
            history.push(redirect);
            return;
          }

          history.push('/b/collection/home');
        } else {
          history.push(window.location.pathname.toLowerCase() + window.location.search);
        }
      }
    });
  }, []);

  const handleChangeType = (type: string) => {
    setAuthType(type);
  };

  let component;

  switch (authType) {
    case 'login':
      component = <Login onAuthChange={handleChangeType} />;
      break;

    case 'register':
      component = <Register onAuthChange={handleChangeType} />;
      break;

    case 'forgotten':
      component = <Forgotten onAuthChange={handleChangeType} />;
      break;

    default:
      component = <Login onAuthChange={handleChangeType} />;
  }

  return (
    <Wrapper>
      <Container>
        <Box textAlign='center' marginBottom='40px'>
          <Logo to='/'>
            Siimpl<span style={{ color: '#43a0d1' }}>.</span>
          </Logo>
        </Box>

        <AuthContainer>{component}</AuthContainer>
      </Container>
    </Wrapper>
  );
}

export default Auth;
