/* eslint-disable @typescript-eslint/no-explicit-any */
import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fetchAPI } from '../../util/data/fetchAPI.util';
import { Bookmark } from '../../defintions/Bookmark.defintion';
import { SearchBar, SearchBarContainer } from './Searchbar.style';

interface SearchBarProps {
  setSearchResults: any;
}

export const Searchbar = (props: SearchBarProps): JSX.Element => {
  const { setSearchResults } = props;
  const [query, setQuery] = useState('');
  const [fuseSearchContent, setFuseSearchContent] = useState<Bookmark[]>([]);
  const [active, setActive] = useState(false);

  const history = useHistory();

  useEffect(() => {
    fetchAPI
      .get('/apiv2/getFuseSearchContent')
      .then((bookmarks: Bookmark[]) => setFuseSearchContent(bookmarks))
      .catch(console.log);
  }, []);

  useEffect(() => {
    setQuery('');
  }, [history.location]);

  const searchFunction = (value: string): void => {
    setQuery(value);
    const keys = ['BookmarkName', 'BookmarkURL'];

    const fuse = new Fuse(fuseSearchContent, {
      includeScore: true,
      ignoreLocation: true,
      keys,
    });

    const result = fuse.search(value, { limit: 10 });
    setSearchResults(result);
  };

  return (
    <SearchBarContainer>
      <SearchBar
        isActive={active}
        type='search'
        placeholder='Search...'
        value={query}
        onChange={(e: { target: { value: string } }) => searchFunction(e.target.value)}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(!!query)}
      />
    </SearchBarContainer>
  );
};
