/**
 * Generic sorting utility for arrays of objects.
 * @param {Array} data - The array of objects to sort.
 * @param {string} key - The property to sort by (e.g., 'name', 'date', 'url').
 * @param {string} order - The sorting order ('asc' for ascending, 'desc' for descending).
 * @returns {Array} - A new sorted array.
 */
export const sortByKey = (data: any, key: string, order = 'asc') => {
  // Validate input
  if (!Array.isArray(data)) {
    throw new Error('Data must be an array.');
  }
  if (!key || typeof key !== 'string') {
    throw new Error('Key must be a valid string.');
  }

  // Define regex for URL cleanup (if sorting by URL)
  const regex = /([a-zA-Z]+?)(s\b|\b)*:\/\/([www.]*)/g;

  return [...data].sort((a, b) => {
    let valueA = a[key];
    let valueB = b[key];

    // Clean URLs if sorting by 'url'
    if (key === 'url' && typeof valueA === 'string' && typeof valueB === 'string') {
      valueA = valueA.replace(regex, '');
      valueB = valueB.replace(regex, '');
    }

    // Handle dates
    if (key === 'date' || key.toLowerCase().includes('date')) {
      valueA = new Date(valueA);
      valueB = new Date(valueB);
    }

    // Handle strings (case-insensitive)
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      valueA = valueA.toLowerCase();
      valueB = valueB.toLowerCase();
    }

    // Determine sort order
    if (order === 'asc') {
      return valueA > valueB ? 1 : -1;
    } else if (order === 'desc') {
      return valueA < valueB ? 1 : -1;
    } else {
      throw new Error('Order must be "asc" or "desc".');
    }
  });
};

export default sortByKey;
