import { HStack, Avatar, Text, Button } from '@chakra-ui/react';
import { HiX } from 'react-icons/hi';

interface Props {
  user: string;
  isOwner?: boolean;
  handleRemoveUser?: (user: string) => void;
}

export const ShareItem = (props: Props) => {
  const { user, isOwner, handleRemoveUser } = props;

  return (
    <HStack alignItems='center' w='100%'>
      <Avatar.Root size='xs'>AB</Avatar.Root>

      <Text m='0' maxW='80%' whiteSpace='pre' textOverflow='ellipsis' overflow='hidden'>
        {user}
      </Text>
      {!isOwner && (
        <Button variant='plain' color='white' marginLeft='auto' onClick={() => handleRemoveUser?.(user)}>
          <HiX style={{ strokeWidth: '0.5px' }} />
        </Button>
      )}

      {isOwner && (
        <Text m='0' fontSize='13px' color='#bcbcbc' marginLeft='auto'>
          Owner
        </Text>
      )}
    </HStack>
  );
};
