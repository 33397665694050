/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useContext, useMemo } from 'react';
import { DataContext } from '../../providers/dataProvider';
import { Button, Form } from 'react-bootstrap';
import { getCollectionIdFromLocation } from '../../util/helpers.util';
import { useForm } from 'react-hook-form';
import SkeletonLoading from '../../Assets/skeletonLoading.gif';

import { BookmarkType } from '../../types/BookmarkType';
import { CollectionTypes } from '../../types/CollectionTypes';
import { fetchAPI } from '../../util/data/fetchAPI.util';
import { Bookmark } from '../../defintions/Bookmark.defintion';
import { Wrapper } from './bookmarkEditor.style';

interface EditBookmarkType {
  bookmark?: BookmarkType;
  isAdd?: boolean;
  close?: () => void;
}

const EditBookmark = (props: EditBookmarkType): JSX.Element => {
  const { bookmark, isAdd, close } = props || {};
  const { Id, BookmarkURL, BookmarkName, BookmarkNotes, BookmarkTags } = bookmark ?? {};

  const { userCollections, setCollectionBookmarks } = useContext(DataContext);

  const activeCollectionId = getCollectionIdFromLocation();
  const activeCollection = useMemo(
    () =>
      ((userCollections as CollectionTypes[]) || []).find(
        (collection) => collection?.CollectionId === activeCollectionId,
      ),
    [],
  );

  const [bookmarkCollection, setBookmarkCollection] = useState(activeCollection?.CollectionName ?? 'Uncategorized');
  const [bookmarkCollectionId, setBookmarkCollectionId] = useState(activeCollection?.CollectionId ?? 'Uncategorized');

  const [showNotesInput, setShowNotesInput] = useState(false);
  const [showTagsInput, setShowTagsInput] = useState(false);

  const { register, handleSubmit } = useForm();
  const onSubmit = (data: any): void => (isAdd ? addBookmark(data) : updateBookmark(data));

  const tags = useMemo(
    () =>
      BookmarkTags?.split(',')?.map((item, index) => (
        <div className='bookmark-tag' key={index}>
          {item}
        </div>
      )),
    [],
  );

  const updateBookmark = (data: any): void => {
    const BookmarkData = {
      ...data,
      Collection: bookmarkCollectionId,
    };

    fetchAPI
      .post('/apiv2/updateBookmark', {
        headers: {
          id: Id,
        },
        body: BookmarkData,
      })
      .then((data) => {
        if (bookmark?.Collection !== data?.Collection) {
          setCollectionBookmarks((prevState: Bookmark[]) =>
            prevState.filter((collectionBookmark: Bookmark) => collectionBookmark.Id !== data.Id),
          );
        } else {
          setCollectionBookmarks((prevState: Bookmark[]) =>
            prevState.map((collectionBookmark: Bookmark) =>
              data.Id === collectionBookmark.Id ? data : { ...collectionBookmark },
            ),
          );
        }

        close?.();
      })
      .catch(console.error);
  };

  const addBookmark = (data: any): void => {
    const bookmarkData = {
      ...data,
      BookmarkType: 'page',
      Collection: bookmarkCollectionId || 'Uncategorized',
      AddedFrom: 'website',
    };

    fetchAPI
      .post('/apiv2/addBookmarkBasicV2', { body: bookmarkData })
      .then((data) => {
        setCollectionBookmarks((prevState: Bookmark[]) =>
          prevState.map((collectionBookmark: Bookmark) => (Id === data.Id ? data : { ...collectionBookmark })),
        );
        close?.();
        if (activeCollectionId === bookmarkCollectionId) {
          setCollectionBookmarks((collectionBookmarks: any) => [
            { ...data, BookmarkImage: SkeletonLoading },
            ...collectionBookmarks,
          ]);
        }
      })
      .catch(console.error);
  };

  return (
    <Wrapper>
      <h4 className='h4 mr-auto'>{isAdd ? 'Add' : 'Edit'} Bookmark</h4>

      <div className='edit-container center'>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form className='edit-wrapper' onSubmit={handleSubmit(onSubmit)}>
          <div className='edit-header'></div>
          <div>
            <div className='input-wrap'>
              <label>Bookmark Name</label>
              <input
                defaultValue={BookmarkName}
                type='text'
                placeholder='Bookmark Name'
                // value={bookmarkName}
                // onChange={(e) => setBookmarkName(e.target.value)}
                spellCheck={false}
                autoComplete='off'
                {...register('BookmarkName')}
              />
            </div>
            <div className='input-wrap'>
              <label>Bookmark Url</label>
              <input
                type='text'
                defaultValue={BookmarkURL}
                placeholder='https://'
                spellCheck={false}
                autoComplete='off'
                {...register('BookmarkURL')}
              />
            </div>
          </div>

          <div>
            <div className='extra-option'>
              <div className='extra-box'>
                <div className='editor-title'>Add notes</div>
                <div className='editor-note'>Add notes to your bookmark</div>
              </div>
              <div className='switch'>
                <Form.Check
                  type='switch'
                  id='add-notes-switch'
                  checked={showNotesInput}
                  onClick={() => setShowNotesInput(!showNotesInput)}
                  label=''
                />
              </div>
            </div>
            <div className='notes-input edit-wrapper w-100 p-0 mb-3'>
              {showNotesInput && (
                <Form.Control
                  as='textarea'
                  className='input mb-3'
                  style={{ height: '100px' }}
                  defaultValue={BookmarkNotes}
                  {...register('BookmarkNotes')}
                />
              )}
            </div>
            <div className='extra-option'>
              <div className='extra-box'>
                <div className='editor-title'>Add tags</div>
                <div className='editor-note'>Add tags to your bookmark to make them easier to find</div>
                <div className='editor-note'>Seperate tags with a comma (,)</div>
              </div>
              <div className='switch'>
                <Form.Check
                  type='switch'
                  id='custom-switch'
                  label=''
                  checked={showTagsInput}
                  onClick={() => setShowTagsInput(!showTagsInput)}
                />
              </div>
            </div>
            <div className='tags-input edit-wrapper w-100 p-0 mb-3'>
              {showTagsInput && (
                <>
                  <Form.Control
                    as='textarea'
                    className='input mb-3'
                    style={{ height: '100px' }}
                    defaultValue={BookmarkTags}
                    {...register('BookmarkTags')}
                  />
                  {tags}
                </>
              )}
            </div>
          </div>
          <hr />
          <div>
            <p className='editor-title'>{isAdd ? 'Collection' : 'Change Collection'}</p>
            {!isAdd && <p className='editor-note mb-0'>Change the collection this bookmark is in</p>}

            <p className='editor-note'>
              Current collection: <strong>{bookmarkCollection}</strong>
            </p>
            <div className='collection-selector-wrapper'>
              <div className='collection-selector'>
                {userCollections.map((item: CollectionTypes, index) => (
                  <div
                    key={index}
                    className={
                      item.CollectionId === bookmarkCollectionId
                        ? 'collection-selector-item active'
                        : 'collection-selector-item'
                    }
                    onClick={() => {
                      setBookmarkCollection(item.CollectionName);
                      setBookmarkCollectionId(item.CollectionId);
                    }}
                  >
                    <p className='mb-0'>{item.CollectionName}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='creator-footer'>
            <Button
              variant='primary'
              type='submit'
              // disabled={!isValidBookmarkURL}
            >
              {isAdd ? 'Add' : 'Update'} Bookmark
            </Button>
          </div>
        </form>
      </div>
    </Wrapper>
  );
};

export default EditBookmark;
