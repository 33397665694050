/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { DataContext } from '../../providers/dataProvider';
import { getToken } from '../../util/auth/firebase';
import { Button, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { getUUIDFromURL } from '../../util/navigation/navigation.util';
import { GoTrash } from 'react-icons/go';
import { Wrapper } from './collectionEditor.style';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function CollectionEditor(props: any) {
  const [collectionId, setCollectionId] = useState('');
  const [collectionName, setCollectionName] = useState('');
  const [collectionDesc, setCollectionDesc] = useState('');
  const [collectionParentId, setCollectionParentId] = useState(null);
  const [collectionParentName, setCollectionParentName] = useState('');

  const [isHidden, setIsHidden] = useState(false);

  const [isNested, setIsNested] = useState(false);

  const { userCollections, setUserCollections } = useContext(DataContext);
  const history = useHistory();

  const activeCollectionId = getUUIDFromURL();
  const collection = useMemo(
    () => userCollections.find((collection) => collection.CollectionId === activeCollectionId),
    [activeCollectionId],
  );

  useEffect(() => {
    if (collection && !props.isAdd) {
      let getCollectionParentName;

      if (collection.CollectionParentId) {
        getCollectionParentName = userCollections.find(
          (collection) => collection.CollectionId === collection.CollectionParentId,
        );

        setCollectionParentName(getCollectionParentName?.CollectionName ?? '');
      }

      setCollectionId(collection.CollectionId);
      setCollectionName(collection.CollectionName);
      setCollectionDesc(collection.CollectionDesc);
      setIsNested(collection.isNested);
      setCollectionParentId(collection.CollectionParentId);
      setIsHidden(collection.isHidden === 1);
    }
  }, [collection]);

  const updateCollection = async () => {
    if (isNested === true && collectionParentId === null) {
      return;
    }

    const accessToken = await getToken();
    const collection = {
      CollectionName: collectionName,
      CollectionDesc: collectionDesc,
      isHidden,
      isNested,
      CollectionParentId: isNested ? collectionParentId : null,
      UpdatedOn: 0,
    };

    fetch('/apiv2/updateCollectionDetails', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        collectionid: collectionId,
      },
      body: JSON.stringify(collection),
    })
      .then((response) => {
        if (response.status !== 200) {
          console.error('error');
        }
        return response.json();
      })
      .then(() => {
        setCollectionName('');
        setCollectionDesc('');
        setIsNested(false);
        setIsHidden(false);
        setCollectionParentId(null);

        const updatedCollection = userCollections.filter((el) => el.CollectionId === collectionId)[0];

        updatedCollection.CollectionName = collection.CollectionName;
        updatedCollection.CollectionDesc = collection.CollectionDesc;
        updatedCollection.isHidden = isHidden ? 1 : 0;
        updatedCollection.isNested = isNested || 0;
        updatedCollection.CollectionParentId = isNested ? collectionParentId : null;

        if (isHidden) {
          setUserCollections((prevState: any[]) => prevState.filter((item) => item.CollectionId !== collectionId));
          props.close();
          return;
        }

        setUserCollections((prevState: any[]) =>
          prevState.map((item) => (item.CollectionId === collectionId ? updatedCollection : { ...item })),
        );
        props.close();
      });
  };

  const addCollection = async () => {
    if (isNested === true && collectionParentId === null) {
      return;
    }

    const accessToken = await getToken();
    const collection = {
      CollectionName: collectionName,
      CollectionDesc: collectionDesc,
      isHidden,
      isNested,
      CollectionParentId: collectionParentId,
      CreatedOn: 0,
    };
    fetch('/apiv2/addCollection', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(collection),
    })
      .then((response) => {
        if (response.status !== 200) {
          console.error('error');
        }
        return response.json();
      })
      .then((data) => {
        data.isOwn = 1;
        data.isEditable = 1;
        data.BookmarkCount = 0;
        data.isListed = null;
        data.isNested = isNested || 0;
        data.isHidden = isHidden ? 1 : 0;
        data.CollectionParentId = isNested ? collectionParentId : null;

        setCollectionName('');
        setCollectionDesc('');
        setIsHidden(false);
        setIsNested(false);
        setCollectionParentId(null);
        setUserCollections([data, ...userCollections]);
        props.close();
      });
  };

  const removeCollection = async () => {
    const customSwal = Swal.mixin({
      customClass: {
        confirmButton: 'btn yes-way',
        cancelButton: 'btn no-way',
      },
      buttonsStyling: false,
    });

    customSwal
      .fire({
        title: 'Delete this collection?',
        text: "All bookmarks within the collection will also be deleted! You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'I want to delete collection',
        cancelButtonText: 'Cancel',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const isParent = userCollections.filter((collection) => collection.CollectionParentId === collectionId);
          if (isParent.length > 0) {
            Swal.fire(
              `Couldn't delete collection`,
              `It look's like you have collections nested within this collection. Please move or delete the nested collections first.`,
              'error',
            );
            return;
          }
          const accessToken = await getToken();

          const collectionDetails = {
            originalId: collectionId,
            moveTo: 'Trash',
          };

          fetch('/apiv2/removeCollectionDetails', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(collectionDetails),
          })
            .then((response) => {
              if (response.status !== 200) {
                console.error('error');
              }
              return response.json();
            })
            .then(() => {
              setUserCollections(userCollections.filter((el) => el.CollectionId !== collectionId));
              history.push(`/b/collection/home`);
              props.close();
            });
        }
      });
  };

  return (
    <Wrapper>
      <h4 className='h4 mr-auto'>{props.isAdd ? 'Add' : 'Edit'} Collection</h4>

      <div style={{ overflow: 'auto', height: '100%' }}>
        <div className='input-wrap'>
          <label>Collection Name</label>
          <input
            type='text'
            placeholder='Collection Name'
            value={collectionName}
            onChange={(e) => setCollectionName(e.target.value)}
            spellCheck={false}
            autoComplete='off'
          />
        </div>
        <div className='input-wrap'>
          <label>Collection Description</label>
          <input
            type='text'
            placeholder='Collection Description'
            value={collectionDesc}
            onChange={(e) => setCollectionDesc(e.target.value)}
            spellCheck={false}
            autoComplete='off'
          />
        </div>
        <div>
          <div className='extra-option mb-3'>
            <div className='extra-box'>
              <div
                className='collection-title'
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                Nest Collection
                <div className='switch'>
                  <Form.Check
                    type='switch'
                    id='collection-list-switch'
                    label=''
                    checked={isNested}
                    onChange={() => setIsNested(!isNested)}
                  />
                </div>
              </div>
              <div className='collection-note'>Nest this collection within another.</div>
              <div className='collection-note' style={{ color: 'white' }}>
                Nested under: <strong>{collectionParentName || 'Not Nested'}</strong>
              </div>
            </div>
          </div>
          {isNested ? (
            <div className='collection-selector-wrapper'>
              <div className='collection-selector'>
                {userCollections
                  .filter((el) => el.isNested === 0)
                  .map((item, index) => (
                    <button
                      key={index}
                      className={
                        item.CollectionId === collectionParentId
                          ? 'collection-selector-item active'
                          : 'collection-selector-item'
                      }
                      style={{ width: '100%', textAlign: 'left', outline: 'none' }}
                      onClick={() => {
                        setCollectionParentId(item.CollectionId);
                        setCollectionParentName(item.CollectionName);
                      }}
                    >
                      <p className='mb-0'>{item.CollectionName}</p>
                    </button>
                  ))}
              </div>
            </div>
          ) : (
            <div />
          )}

          <div className='extra-option'>
            <div className='extra-box'>
              <div
                className='collection-title'
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                Hide Collection{' '}
                <div className='switch'>
                  <Form.Check
                    type='switch'
                    id='collection-hidden-switch'
                    label=''
                    checked={isHidden}
                    onChange={() => setIsHidden(!isHidden)}
                  />
                </div>
              </div>
              <div className='collection-note'>Hidden collections will require a pin to see</div>
              <div className='collection-note' style={{ color: 'white' }}>
                Collection is currently: <strong>{isHidden ? 'Hidden' : 'Visible'}</strong>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className='d-flex creator-footer' style={{ justifyContent: 'space-between' }}>
          <Button
            variant='primary'
            className='button-stroke button-small ml-0'
            onClick={() => {
              props.isAdd ? addCollection() : updateCollection();
            }}
            disabled={!collectionName}
          >
            {props.isAdd ? 'Add' : 'Update'} Collection
          </Button>

          {!props.isAdd && (
            <Button
              variant='variant'
              style={{
                background: '#af0000',
                width: '37px',
                height: '37px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px',
              }}
              className='p-0 m-0'
              onClick={() => removeCollection()}
            >
              <GoTrash />
            </Button>
          )}
        </div>
      </div>
    </Wrapper>
  );
}
