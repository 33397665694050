import styled from '@emotion/styled';
import { Button as ChakraButton } from '@chakra-ui/react';

export const Button = styled(ChakraButton)`
  height: 46px;
  background: white;
  min-width: 190px;
  overflow: hidden;
  border-radius: 12px;
  color: black;

  h5 {
    font-size: 26px;
    margin: 0;
    line-height: 14px;
  }

  p {
    margin: 0;
    font-size: 11px;
    line-height: 18px;
    padding-left: 2px;
  }
`;
