import React, { useState, useEffect, useContext } from 'react';
import { Route, useHistory } from 'react-router-dom';
import DataProvider, { DataContext } from '../providers/dataProvider';
import { ReactComponent as MobileMenu } from '../Assets/svg/mobileMenu.svg';
import { Button } from 'react-bootstrap';
import SearchContainer from '../Elements/SearchContainer';
import './Application.scss';
import '../Dark-Theme.scss';
import { LoadingLogo } from '../Components/loadingLogo/LoadingLogo.component';

import { SideNavigation } from '../Components/SideNavigation/SideNavigation.component';
import { Overlay } from '../Components/SideNavigation/Components/Overlay.component';
import { fetchAPI } from '../util/data/fetchAPI.util';
import { Searchbar } from '../Components/Searchbar/Searchbar.component';
import { SiimplAdd } from '../Components/SiimplAdd/SiimplAdd.component';
import { Text, VStack } from '@chakra-ui/react';
import { BookmarksV2 } from '../pages/bookmarks/bookmarks';
import Tabs from '../pages/tabs/tabs';
import Snippets from '../pages/snippets/snippets';
import { useMediaQuery } from 'react-responsive';
import { FaSearch } from 'react-icons/fa';
import { useGetSharedCollections } from '../hooks/useGetSharedCollections/useGetSharedCollections.hook';

function Application(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [sidebarActive, setSidebarActive] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isMobileSearchbarVisible, setIsMobileSearchbarVisible] = useState(false);

  const { setUserCollections, setSharedCollections } = useContext(DataContext);
  const history = useHistory();
  const isMobile = useMediaQuery({
    query: '(max-width: 1137px)',
  });

  useEffect(() => {
    setSearchResults([]);
  }, [history.location]);

  useEffect(() => {
    getCollections();
  }, []);

  const getCollections = (): void => {
    fetchAPI
      .get('/apiv2/getCollections')
      .then((data) => {
        setUserCollections(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        throw new Error(err);
      });
  };

  const { data, loading, error } = useGetSharedCollections();

  if (error) {
    console.error(error);
  }

  useEffect(() => {
    setSharedCollections(data);
  }, [data]);

  const openSidebar = (open: boolean): void => {
    setSidebarActive(open);
    const body = document.querySelector('body');

    if (body == null) {
      return;
    }

    if (open) {
      body.style.overflow = 'hidden';
      return;
    }
    body.style.overflow = 'auto';
  };

  const toggleSearchBar = () => {
    setIsMobileSearchbarVisible(!isMobileSearchbarVisible);
  };

  if (isLoading || loading) {
    return (
      <VStack
        justifyContent='center'
        color='white'
        style={{ height: '100vh', background: 'black', textAlign: 'center' }}
      >
        <LoadingLogo />
        <Text fontSize='20px' width='60%'>
          Our goal is to create a product and service that you’re satisfied with and use every day.
        </Text>
        <Text fontSize='20px' width='60%'>
          We are loading your items.
        </Text>
      </VStack>
    );
  }

  return (
    <div className='siimpl' id='siimpl-application' data-type='dark-theme'>
      <header style={{ borderBottom: '1px solid rgb(168 179 207 / 38%)', position: 'relative' }}>
        <div className='section-left'>
          <Button variant='link' className='mob-menu-btn d-none' onClick={() => openSidebar(true)}>
            <MobileMenu />
          </Button>

          <button className='siimpl-logo' onClick={() => history.push('/')}>
            Siimpl<span style={{ color: '#43a0d1' }}>.</span>
          </button>
        </div>

        {!isMobile && <Searchbar setSearchResults={setSearchResults} />}

        <div className='section-right'>
          {isMobile && (
            <Button
              variant='link'
              style={{
                height: '38px',
                width: '38px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '10px',
              }}
              onClick={toggleSearchBar}
            >
              <FaSearch color='white' size='22px' />
            </Button>
          )}
          <SiimplAdd />
        </div>

        <div
          style={{
            background: '#223348',
            height: isMobileSearchbarVisible && isMobile ? '52px' : '0px',
            overflow: 'hidden',
            position: 'absolute',
            top: '60px',
            left: 0,
            right: 0,
            padding: isMobileSearchbarVisible && isMobile ? '6px' : '0px',
            transition: '.5s',
          }}
        >
          <Searchbar setSearchResults={setSearchResults} />
        </div>
      </header>

      <div className='siimpl-container'>
        <div className='siimpl-wrapper'>
          <div className='siimpl-content'>
            <Overlay isActive={sidebarActive} handleClick={() => openSidebar(false)} />
            <SideNavigation sidebarActive={sidebarActive} setSidebarActive={setSidebarActive} />
            <div className='siimpl-container-right'>
              {searchResults.length > 0 ? (
                <SearchContainer
                  searchResults={searchResults}
                  setActiveCollection={(collectionId: string) => {
                    collectionId === 'Selection Text'
                      ? history.push('/b/text-snippets')
                      : history.push(`/b/collection/${collectionId}`);
                  }}
                />
              ) : (
                <>
                  <Route path='/b/collection/:id' render={() => <BookmarksV2 />} />
                  <Route path='/b/tabs' render={() => <Tabs />} />
                  <Route path='/b/text-snippets' render={() => <Snippets />} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ApplicationWrapper = () => {
  return (
    <DataProvider>
      <Application />
    </DataProvider>
  );
};

export default ApplicationWrapper;
