import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { HomePage } from './pages/homePage/homePage';
import { Privacy } from './pages/companyPage/privacy';
import { Terms } from './pages/companyPage/terms';
import { Data } from './pages/companyPage/data';
import { NoAccessPage } from './pages/noAccessPage/noAccessPage';
import { useUser } from '../../hooks/useUser/userUser.hook';

function App() {
  const { user, userLoading } = useUser();

  return (
    <Switch>
      <Route path='/terms' exact render={() => <Terms />} />
      <Route path='/privacy' exact render={() => <Privacy />} />
      <Route path='/datadeletion' exact render={() => <Data />} />

      <Route path='/' exact render={() => <HomePage />} />
      {!user && !userLoading && <Route path='*' render={() => <NoAccessPage />} />}
    </Switch>
  );
}

export default App;
