import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: calc(100% - 300px);
  overflow: hidden;
`;

export const EditorContainer = styled(Box)`
  width: 100%;
  max-width: 60vw;
  padding: 20px;
  overflow-y: auto;
  background: #15191f;
  border-radius: 12px;
  outline: solid 1px rgba(31, 41, 55, 0.9490196078);

  .jodit-wysiwyg {
    background-color: #15191f;
    font-size: 18px;
    color: white !important;

    div {
      background-color: #15191f;
      font-size: 18px;
      color: white !important;
    }
  }
`;
