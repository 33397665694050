import { useContext, useEffect, useState } from 'react';
import { copyToClipboard } from '../../util/data/ui.util';
import { Wrapper } from './collectionSharer.style';
import { Box, HStack, Input, Stack, Text, VStack } from '@chakra-ui/react';
import { useGetCollectionSharedUsers } from '../../hooks/useGetCollectionSharedUsers/useGetCollectionSharedUsers.hook';
import { fetchAPI } from '../../util/data/fetchAPI.util';
import { Button } from '../../styles/button.style';
import { ShareItem } from './components/shareItem.component';
import { useUser } from '../../hooks/useUser/userUser.hook';
import * as EmailValidator from 'email-validator';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import { config } from '../../config/config';
import Swal from 'sweetalert2';
import { DataContext } from '../../providers/dataProvider';

interface CollectionSharerProps {
  selectedCollection: any;
  onClose: () => void;
}

export default function CollectionSharer(props: CollectionSharerProps) {
  const { selectedCollection, onClose } = props;
  const [email, setEmail] = useState('');
  const [collectionUsers, setCollectionUsers] = useState<string[]>([]);
  const [newUsers, setNewUsers] = useState<string[]>([]);
  const [removedUsers, setRemovedUsers] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [newUserError, setNewUserError] = useState('');
  const [shareId, setShareId] = useState(selectedCollection.ShareId);
  const [isShared, setIsShared] = useState(selectedCollection.ShareId && selectedCollection.isActive);

  const { setUserCollections } = useContext(DataContext);

  const { data, loading, error } = useGetCollectionSharedUsers();
  const { user } = useUser();

  const isMobile = useMediaQuery({
    query: '(max-width: 1137px)',
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const existingEmails = data.map((user) => user.userEmail);
    setCollectionUsers(existingEmails);
  }, [data]);

  const handleAddUser = () => {
    if (!email) return;

    if (!EmailValidator.validate(email)) {
      setNewUserError('Not a valid email');
      return;
    }

    if (newUsers.includes(email) || collectionUsers.includes(email)) {
      setNewUserError('User already added');
      return;
    }

    if (email === user.email) {
      setNewUserError('You cannot add yourself');
      return;
    }

    setNewUsers([...newUsers, email]);
    setEmail('');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    setEmail((event.target as HTMLInputElement).value);
    setNewUserError('');

    if (event.key === 'Enter') {
      handleAddUser();
    }
  };

  const handleRemoveUser = (user: string) => {
    setCollectionUsers(collectionUsers.filter((u) => u !== user));
    setRemovedUsers([...removedUsers, user]);
  };

  const handleCollectionShareUpdate = () => {
    setIsSaving(true);
    const collectionData = {
      collectionId: selectedCollection.CollectionId,
      newUsers,
      removedUsers,
    };

    fetchAPI
      .post('/apiv2/updateUsersInCollection', {
        body: collectionData,
      })
      .then((data) => {
        console.log(data);
        setCollectionUsers([...collectionUsers, ...newUsers]);
        setNewUsers([]);
        setRemovedUsers([]);
        toast.info(data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error('There was an error updating users', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.error(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleChangeShareVisibility = () => {
    fetchAPI
      .post('/apiv2/setCollectionVisibility', {
        body: {
          CollectionId: selectedCollection.CollectionId,
        },
      })
      .then((data) => {
        setShareId(data);
        setIsShared(true);
        setUserCollections((prevCollections: any) => {
          return prevCollections.map((collection: any) => {
            if (collection.CollectionId === selectedCollection.CollectionId) {
              return { ...collection, ShareId: data, isActive: true };
            }
            return collection;
          });
        });
      })
      .catch((err) => console.error(err));
  };

  const handleCollectionUnshare = () => {
    const customSwal = Swal.mixin({
      customClass: {
        confirmButton: 'btn yes-way',
        cancelButton: 'btn no-way',
      },
      buttonsStyling: false,
    });

    customSwal
      .fire({
        title: 'Unshare this collection?',
        text: 'Unsharing this collection will revoke access for the user it was previously shared with, meaning they will no longer be able to view its contents. If they still need access, you may need to re-share it with them.',
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'I want to unshare collection',
        cancelButtonText: 'Cancel',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          fetchAPI
            .post('/apiv2/unshareCollection', {
              body: {
                CollectionId: selectedCollection.CollectionId,
              },
            })
            .then(() => {
              setShareId(null);
              setIsShared(false);
              setUserCollections((prevCollections: any) => {
                return prevCollections.map((collection: any) => {
                  if (collection.CollectionId === selectedCollection.CollectionId) {
                    return { ...collection, ShareId: null, isActive: false };
                  }
                  return collection;
                });
              });
            });
        }
      });
  };

  return (
    <Wrapper>
      {!isShared && (
        <>
          <Text fontSize='24px'>This collection is not shared yet.</Text>
          <Text fontSize='18px'>
            Share it with others to collaborate, organize, and access it easily—because great ideas are even better when
            shared!
          </Text>
          <Button onClick={handleChangeShareVisibility} background='#2c77e7' size='md' mt='20px'>
            Share this collection with others
          </Button>
        </>
      )}

      {!!isShared && (
        <>
          <Text as='h4'>Share Collection</Text>
          <Text m='0' fontSize='14px'>
            Collection viewable at
          </Text>
          <Input
            cursor='pointer'
            readOnly
            value={`${config.baseUrl}/p/${shareId}`}
            onClick={(e) => copyToClipboard((e.target as HTMLInputElement).value)}
            mb='8px'
          />
          <Button variant='plain' m='0' style={{ padding: 0 }} height='18px' onClick={handleCollectionUnshare}>
            Unshare this collection
          </Button>
          <Box border='solid 0.5px #4c4c4c' w='90%' m='auto' marginY='22px' />
          <Box>
            <Text m='0' fontSize='14px'>
              People with edit access
            </Text>
            <Input
              placeholder='Email'
              value={email}
              onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
              onKeyDown={handleKeyDown}
            />
            {newUserError && (
              <Text fontSize='14px' m='0' style={{ color: '#fe7777' }}>
                {newUserError}
              </Text>
            )}
            <Text fontSize='14px' mt='4px'>
              You can only add users that are already on Siimpl. If a user is not on Siimpl then they will recieve an
              email asking them to join. Once they join you can add them to the collection.
            </Text>
            <VStack border='solid 1px grey' borderRadius='5px' p='10px' mt='12px' h='240px' overflowY='auto'>
              {newUsers?.length > 0 && (
                <VStack alignItems='flex-start' background='#a9d1ff52' width='100%' p='10px' borderRadius='8px'>
                  <Text m='0' fontSize='12px'>
                    New Users
                  </Text>
                  {newUsers.map((user, index) => (
                    <ShareItem key={index} user={user} handleRemoveUser={handleRemoveUser} />
                  ))}
                </VStack>
              )}

              <ShareItem user={user.email} isOwner={true} />
              {loading && <Text>Loading...</Text>}
              {error && <Text>Error loading users</Text>}

              {collectionUsers?.map((user: any, index) => (
                <ShareItem key={index} user={user} handleRemoveUser={handleRemoveUser} />
              ))}
            </VStack>
            <HStack mt='20px'>
              {isMobile && (
                <Button mt='10px' size='sm' onClick={onClose} variant='solid' background='grey'>
                  Cancel
                </Button>
              )}
              <Button
                disabled={isSaving || (!newUsers.length && !removedUsers.length)}
                mt='10px'
                size='sm'
                onClick={handleCollectionShareUpdate}
                variant='solid'
                marginLeft='auto'
                background='blue'
              >
                {isSaving ? 'Saving...' : 'Save'}
              </Button>
            </HStack>
          </Box>
        </>
      )}
    </Wrapper>
  );
}
