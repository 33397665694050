import React from 'react';
import styled from '@emotion/styled';
import { Form, FormControlProps } from 'react-bootstrap';
import { InputBaseProps } from '../../defintions/baseTypes.defintions';

export const SearchBarContainer = styled.div`
  position: relative;
  margin: 0px auto;
  width: 600px;
  max-width: 80vw;
`;

type Searchbar = FormControlProps &
  InputBaseProps & {
    isActive?: boolean;
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SearchBar = styled((props: Searchbar) => <Form.Control {...props} />)`
  font-size: 20px !important;
  font-weight: 300;
  width: ${(props) => (props.isActive ? '25vw' : '7.5vw')};
  width: 100%;
  height: 40px;
  background: #fbfbfb;
  padding-left: 20px;
  margin: auto !important;
  border: none;
  outline: solid 1px #afafaff2;
  border-radius: 5px;
`;
