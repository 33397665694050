import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding: 16px 24px;
`;

export const SnippetItem = styled(Box)<{ active: boolean }>`
  padding: 12px;
  border-radius: 8px;
  background: ${({ active }) => (active ? '#1f2937' : '#15191f')};
  outline: solid 1px rgba(31, 41, 55, 0.9490196078);
  cursor: pointer;

  &:hover {
    background: #1f2937;
  }
`;

export const SnippetText = styled.p`
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  color: #777;
  cursor: pointer;
  display: -webkit-box;
  font-size: 13px;
  max-height: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;
